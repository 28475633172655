<script>
export default {
	filters: {
		integer(price) {
			if (!price && price !== 0 && price !== '0') {
				console.log('Ошибка цены', price);
				return ' - ';
			}
			return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
		},
	}
};
</script>