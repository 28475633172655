<template>
  <div class="employee-projects">
    <div class="employee-projects__table desktop">
      <el-table
        :data="employeeProjects"
        @header-click="sortData"
        v-loading="loading"
      >
        <el-table-column
          :index="0"
          :label-class-name="
            typeSort === 'title'
              ? 'is-sortable sortable ' + sortOrder
              : 'is-sortable'
          "
        >
          <template slot="header" slot-scope="scope">
            <div class="table-th--title">
              Название проекта
              <svg
                width="6"
                height="9"
                viewBox="0 0 6 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M3 5H0L3 9L6 5H3Z" fill="#A2A7BE" />
                <path d="M3 4L6 4L3 0L0 4L3 4Z" fill="#A2A7BE" />
              </svg>
            </div>
            <el-select
              v-if="statusList.length"
              v-model="selectedStatus"
              placeholder="Статус проекта"
              class="el-select_filter"
              :key="scope.column.index"
              @change="filterByStatus"
            >
              <el-option
                v-for="item in statusList"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
          </template>
          <template slot-scope="scope">
            <div
              class="employee-projects__table-item"
              @click="getDetailProject(scope.row.project_id)"
            >
              <span class="employee-projects__table-item--bold">
                {{ scope.row.title }}
              </span>
              <br />
              <span>
                {{ scope.row.address }}
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column :index="1">
          <template slot="header">
            <span class="hide-sm  hide-xs">Начало работ</span>
            <span class="hide-lg  hide-md">Проект</span>
          </template>
          <template slot-scope="scope">
            <div class="professions__table-item">
              <span v-if="scope.row.workday_start">
                {{ getHHMMFromString(scope.row.workday_start) }}
              </span>
              <span v-else class="color-muted">--</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column :index="7">
          <template slot="header">
            <span>Время перерыва</span>
          </template>
          <template slot-scope="scope">
            <div class="professions__table-item">
              <span v-if="scope.row.dinner_start && scope.row.dinner_end">
                {{ getHHMMFromString(scope.row.dinner_start) }} -
                {{ getHHMMFromString(scope.row.dinner_end) }}
              </span>
              <span v-else class="color-muted">--</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column :index="2">
          <template slot="header">
            Окончание работ
          </template>
          <template slot-scope="scope">
            <div class="professions__table-item">
              <span v-if="scope.row.workday_end">
                {{ getHHMMFromString(scope.row.workday_end) }}
              </span>
              <span v-else class="color-muted">--</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column :index="3">
          <template slot="header" slot-scope="scope">
            <div class="table-th--title">
              Специальности
            </div>
            <el-select
              v-if="specialitiesFilterList && specialitiesFilterList.length"
              v-model="selectedSpeciality"
              filterable
              placeholder="Все специальности"
              class="el-select_filter"
              :key="scope.column.index"
              @change="filterBySpeciality"
            >
              <el-option
                v-for="item in specialitiesFilterList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </template>
          <template slot-scope="scope">
            <div class="employee-projects__table-item">
              <span v-if="scope.row.speciality">
                {{ scope.row.speciality.name }}
              </span>
              <span v-else class="color-muted">Специальностей нет</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column :index="4" width="100">
          <template slot="header">
            Ставка
          </template>
          <template slot-scope="scope">
            <div class="professions__table-item">
              <span v-if="scope.row.payment">
                {{ scope.row.payment }}
              </span>
              <span v-else class="color-muted">--</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column :index="5" width="160" :align="'center'">
          <template slot="header">
            Показывать ставку
          </template>
          <template slot-scope="scope">
            <div class="professions__table-item">
              {{ scope.row.show_stavka ? "Да" : "Нет" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column :index="6" width="100">
          <template slot-scope="scope">
            <div class="employee-projects__table-actions">
              <el-dropdown
                class="hide-sm  hide-xs"
                trigger="click"
                @command="handleCommand"
              >
                <span class="el-dropdown-link">
                  <img src="@/assets/icons/icon-option.svg" alt="commands" />
                </span>
                <el-dropdown-menu
                  slot="dropdown"
                  class="employee-projects__options"
                >
                  <el-dropdown-item
                    :command="{ name: 'more', id: scope.row.project_id }"
                  >
                    <img src="@/assets/icons/icon-info.svg" alt="more" />
                    Подробнее
                  </el-dropdown-item>
                  <el-dropdown-item
                    :command="{
                      name: 'dismiss',
                      id: scope.row.id,
                      worker_id: scope.row.worker_id
                    }"
                    class="el-dropdown-menu__item removed"
                  >
                    <img
                      src="@/assets/icons/icon-removed-red.svg"
                      alt="dismiss"
                    />
                    Уволить из проекта
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="mobile">
      <div class="card" v-for="row in employeeProjects" :key="row.id">
        <div class="row">
          <div class="col">
            Название проекта
          </div>
          <div class="col">
            <div
              class="employee-projects__table-item"
              @click="getDetailProject(row.project_id)"
            >
              <span class="employee-projects__table-item--bold">
                {{ row.title }}
              </span>
              <br />
              <span>
                {{ row.address }}
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">Начало работ</div>
          <div class="col">
            <div class="professions__table-item">
              <span v-if="row.workday_start">
                {{ getHHMMFromString(row.workday_start) }}
              </span>
              <span v-else class="color-muted">--</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">Время перерыва</div>
          <div class="col">
            <div class="professions__table-item">
              <span v-if="row.dinner_start && row.dinner_end">
                {{ getHHMMFromString(row.dinner_start) }} -
                {{ getHHMMFromString(row.dinner_end) }}
              </span>
              <span v-else class="color-muted">--</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            Окончание работ
          </div>
          <div class="col">
            <div class="professions__table-item">
              <span v-if="row.workday_end">
                {{ getHHMMFromString(row.workday_end) }}
              </span>
              <span v-else class="color-muted">--</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            Специальности
          </div>
          <div class="col">
            <div class="employee-projects__table-item">
              <span v-if="row.speciality">
                {{ row.speciality.name }}
              </span>
              <span v-else class="color-muted">Специальностей нет</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">Ставка</div>
          <div class="col">
            <div class="professions__table-item">
              <span v-if="row.payment">
                {{ row.payment }}
              </span>
              <span v-else class="color-muted">--</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            Показывать ставку
          </div>
          <div class="col">
            {{ row.show_stavka ? "Да" : "Нет" }}
          </div>
        </div>
        <div class="row">
          <div class="col"></div>
          <div class="col">
            <div class="more-options">
              <span
                @click="
                  visibleMoreOptions = true;
                  activeProject = row;
                "
                class="el-dropdown-link"
              >
                <img src="@/assets/icons/icon-option.svg" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="employee-projects__nav">
      <Pagination :info="pagination" @changedPage="changePage" />
    </div>
    <el-dialog
      title="Действия"
      :visible.sync="visibleMoreOptions"
      width="288px"
      custom-class="more-options"
    >
      <el-dropdown-item
        @click.native="
          visibleMoreOptions = false;
          handleCommand({ name: 'more', id: activeProject.project_id });
        "
      >
        <img src="@/assets/icons/icon-info.svg" alt="more" />
        Подробнее
      </el-dropdown-item>
      <el-dropdown-item
        @click.native="
          visibleMoreOptions = false;
          handleCommand({
            name: 'dismiss',
            id: activeProject.id,
            worker_id: activeProject.worker_id
          });
        "
        class="el-dropdown-menu__item removed"
      >
        <img src="@/assets/icons/icon-removed-red.svg" alt="dismiss" />
        Уволить из проекта
      </el-dropdown-item>
    </el-dialog>
  </div>
</template>

<script>
import Pagination from "../blocks/Pagination";
import { getHHMMFromString } from "../../utils/date-formatter";

export default {
  name: "EmployeeProjects",
  components: {
    Pagination
  },
  data() {
    return {
      loading: false,
      visibleMoreOptions: false,
      activeProject: null,
      typeSort: "",
      ascOrder: true,
      page: 1,
      pageCount: 8,
      selectedSpeciality: "",
      selectedProject: "",
      selectedStatus: "",
      statusList: [
        { name: "Все проекты", value: "" },
        { name: "Активные", value: 0 },
        { name: "Завершенные", value: 1 }
      ]
    };
  },
  computed: {
    employeeProjects() {
      return this.$store.state.Employee.employeeProjects?.map(project => {
        return {
          ...project,
          title: this.projectsListAll?.find(
            item => item.id === project.project_id
          ).title,
          visibleMoreOptions: false
        };
      });
    },
    specialitiesList() {
      return this.$store.state.Employee.specialitiesList;
    },
    specialitiesFilterList() {
      let arr = [];
      if (this.specialitiesList) {
        arr = JSON.parse(JSON.stringify(this.specialitiesList));
        arr.unshift({ id: "", name: "Все специальности" });
      }
      return arr;
    },
    projectsListAll() {
      return this.$store.state.Employee.projectsListAll;
    },
    projectsFilterList() {
      let arr = [];
      if (this.projectsListAll) {
        arr = JSON.parse(JSON.stringify(this.projectsListAll));
        arr.unshift({ id: "", title: "Все проекты" });
      }
      return arr;
    },
    pagination() {
      return this.$store.state.Employee.employeeProjectsPagination;
    },
    sortOrder() {
      let order = "asc";
      if (!this.ascOrder) order = "desc";
      return order;
    }
  },
  created() {
    this.getProjects();
    this.$store.dispatch("Employee/getSpecialities");
    this.$store.dispatch("Employee/getProjectsAll", {
      employee: this.$route.params.id
    });
  },
  methods: {
    getHHMMFromString,
    getDetailProject(id) {
      this.$router.push({ path: "/projects/" + id });
    },

    getProjects() {
      this.loading = true;
      const id = this.$route.params.id;
      const params = {
        page: this.page,
        pageCount: this.pageCount,
        speciality: this.selectedSpeciality ? this.selectedSpeciality : null,
        project: this.selectedProject ? this.selectedProject : null,
        project_status:
          typeof this.selectedStatus === "number" ? this.selectedStatus : null,
        sortField: this.typeSort ? this.typeSort : null,
        sort: this.typeSort ? this.sortOrder : null
      };
      this.$store
        .dispatch("Employee/getProjectsByEmployeeId", { params, id })
        .then(() => {
          this.loading = false;
        });
    },
    changePage(page, count) {
      this.page = page;
      this.pageCount = count;
      this.getProjects();
    },
    addEmployee() {
      this.$router.push({ name: "NewEmployee" });
    },
    handleCommand(command) {
      switch (command.name) {
        case "more":
          this.$router.push({
            name: "Project",
            params: { id: command.id }
          });
          break;
        case "dismiss":
          this.dismissEmployee(command.id, command.worker_id);
          break;
      }
    },
    dismissEmployee(id, worker_id) {
      this.$confirm(
        `<div class="remove-message">
        <img src="${require("@/assets/icons/icon-delete-red.svg")}" alt="Удаление исполнителя">
        <div>
          <div class="remove-message__title">
            Уволить исполнителя
          </div>
          <div class="remove-message__text">
            Вы действительно хотите уволить испольнителя?
          </div>
        </div>
      </div>`,
        {
          customClass: "remove-message__box",
          dangerouslyUseHTMLString: true,
          confirmButtonText: "Уволить",
          cancelButtonText: "Отменить",
          showClose: false
        }
      ).then(() => {
        const data = {
          worker_id
        };
        this.$store
          .dispatch("Employee/dismissEmployee", { data, id })
          .then(() => {
            this.getProjects();
            this.$notify({
              title: "Успех",
              message: "Сотрудник уволен",
              type: "success"
            });
          })
          .catch(err => {
            console.error(err);
            this.$notify({
              title: "Ошибка",
              message: "Ошибка, попробуйте еще раз",
              type: "error"
            });
          });
      });
    },
    sortData(row) {
      switch (row.index) {
        case 0:
          if (this.typeSort === "title") {
            this.ascOrder = !this.ascOrder;
          } else {
            this.ascOrder = true;
          }
          this.typeSort = "title";
          break;
      }
      this.getProjects();
    },
    filterBySpeciality() {
      this.page = 1;
      this.getProjects();
    },
    filterByProject() {
      this.page = 1;
      this.getProjects();
    },
    filterByStatus() {
      this.page = 1;
      this.getProjects();
    }
  }
};
</script>

<style lang="scss">
@import "src/assets/styles/helpers/variables";
@import "src/assets/styles/helpers/mixin";

.employee-projects {
  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.6rem;

    .el-button {
      width: 20.2rem;
    }
  }

  &__table {
    .el-table th {
      padding: 0;
      vertical-align: top;

      .cell {
        padding-top: 1.6rem;
        padding-bottom: 1.6rem;
      }
    }

    &-item {
      &--bold {
        font-weight: 600;
        cursor: pointer;
        color: $primary;
        &:hover {
          color: $text;
        }
      }
    }

    &-list {
      list-style: none;

      &-item {
        margin-bottom: 0.8rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &-status {
      &-icon {
        vertical-align: top;
        margin: -0.1rem 0 0.1rem;
      }
    }

    &-actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 1.4rem;

      &__chat {
        margin-right: 2rem;
        margin-top: 0.7rem;
      }
    }
  }

  &__options {
    border-radius: 2px;
    border: none;
    box-shadow: 6px 6px 32px rgba(0, 0, 0, 0.08);

    .el-dropdown-menu__item {
      display: flex;
      align-items: center;
      font-size: 1.2rem;
      line-height: 1.6rem;
      color: $text;
      padding: 0.5rem 2.2rem;

      &.removed {
        color: $error;
      }

      img {
        margin-right: 0.8rem;
      }
    }
  }
}

@media (max-width: $screen-sm-max) {
  .employee-projects {
    .employee-projects__table {
      margin: 0 -16px;
    }

    .el-table__header-wrapper {
      margin-bottom: 4px;
    }

    .el-table__header {
      display: block;
      width: 100% !important;

      thead {
        display: block;
      }

      tr {
        display: flex;
        flex-wrap: wrap;
        background: none;
      }

      th {
        &:nth-child(2) {
          border-radius: 0;
          width: 100%;
          margin-top: 16px;

          .cell {
            padding: 8px 16px;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
          }
        }

        &:last-child,
        &:nth-child(3),
        &:nth-child(5),
        &:nth-child(6) {
          display: none;
        }

        &:nth-child(1),
        &:nth-child(4) {
          margin: 0 16px 4px;
          order: -15;
          width: 100%;
          background: none;

          .cell {
            padding: 0;
          }

          .table-th--title {
            display: none;
          }
        }

        svg {
          display: none;
        }
      }

      .el-select {
        width: 100%;
      }
    }

    .el-table__body-wrapper {
      table,
      tbody {
        display: block;
        width: 100% !important;
      }
      tr {
        display: flex;
        flex-wrap: wrap;
        padding: 16px;
        position: relative;
        margin-bottom: 4px;
      }
      td {
        position: static;
        border-radius: 0;
        padding: 0;
        border: none;

        &:nth-child(1) {
          width: calc(100% - 24px);
          margin-bottom: 8px;
          order: -25;

          .employee-projects__table-item--bold {
            color: #31333b;
          }
        }

        &:nth-child(4) {
          width: 100%;
          order: -15;
        }

        &:nth-child(2),
        &:nth-child(6),
        &:nth-child(3) {
          display: none;
        }

        &:nth-child(5) {
          color: #1199f0;
        }

        &:nth-child(7) {
          width: 24px;
          order: -20;
        }

        .cell {
          padding: 0;
          line-height: 20px;
        }
      }
      .employee-projects__table-actions__chat {
        position: absolute;
        right: 16px;
        bottom: 16px;
        margin: 0;

        img {
          margin: 0;
          position: static;
        }
      }
    }
  }
  .employee-projects__table-actions {
    padding: 0;
  }
}
</style>
