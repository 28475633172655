var render = function () {
  var _vm$employeeReportTot, _vm$employeeReportTot2, _vm$employeeReportTot3;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "employee-report"
  }, [_c('div', {
    staticClass: "employee-report-table"
  }, [_c('el-table', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    ref: "tableReport",
    attrs: {
      "data": _vm.localReportTable
    }
  }, [_c('el-table-column', {
    attrs: {
      "class-name": "employee-report-table__column-1",
      "index": 0,
      "min-width": "200"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "table-th--title"
        }, [_vm._v(" Дата ")]), _c('el-date-picker', {
          staticClass: "el-date-editor_filter",
          attrs: {
            "type": "daterange",
            "picker-options": _vm.pickerOptions,
            "format": "dd.MM.yyyy",
            "value-format": "yyyy-MM-dd",
            "start-placeholder": "От",
            "end-placeholder": "До",
            "clearable": false
          },
          on: {
            "change": _vm.changeDateSort
          },
          model: {
            value: _vm.dateSort,
            callback: function callback($$v) {
              _vm.dateSort = $$v;
            },
            expression: "dateSort"
          }
        })];
      }
    }, {
      key: "default",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "employee-report-table__content"
        }, [scope.row.date ? [_c('div', [_vm._v(" " + _vm._s(new Date(scope.row.date).toLocaleString("ru").substr(0, 10)) + " ")]), _c('div', [_vm._v(" " + _vm._s(_vm.getDayOfWeek(scope.row.date)) + " ")])] : _c('span', {
          staticClass: "color-muted"
        }, [_vm._v("-")])], 2)];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "index": 1,
      "min-width": "200"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "table-th--title"
        }, [_vm._v("Название проекта")]), _vm.projectsFilterList && _vm.projectsFilterList.length ? _c('el-select', {
          key: scope.column.index,
          staticClass: "el-select_filter",
          attrs: {
            "filterable": "",
            "placeholder": "Все проекты"
          },
          on: {
            "change": _vm.filterByProject
          },
          model: {
            value: _vm.selectedProject,
            callback: function callback($$v) {
              _vm.selectedProject = $$v;
            },
            expression: "selectedProject"
          }
        }, _vm._l(_vm.projectsFilterList, function (item) {
          return _c('el-option', {
            key: item.id,
            attrs: {
              "label": item.title,
              "value": item.id
            }
          });
        }), 1) : _vm._e()];
      }
    }, {
      key: "default",
      fn: function fn(scope) {
        var _scope$row$project, _scope$row$project2;

        return [_c('div', {
          staticClass: "employee-plan__table-item"
        }, [(_scope$row$project = scope.row.project) !== null && _scope$row$project !== void 0 && _scope$row$project.title ? _c('span', {
          staticClass: "employee-plan__table-item--bold"
        }, [_vm._v(" " + _vm._s(scope.row.project.title) + " ")]) : _vm._e(), _c('br'), (_scope$row$project2 = scope.row.project) !== null && _scope$row$project2 !== void 0 && _scope$row$project2.address ? _c('span', [_vm._v(" " + _vm._s(scope.row.project.address) + " ")]) : _vm._e()])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "class-name": "employee-report-table__column-3",
      "index": 2,
      "min-width": "200"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        var _scope$row$statistics;

        return [_c('div', {
          staticClass: "employee-report-table__content",
          attrs: {
            "data-header": "Часов на рабочем месте:"
          }
        }, [_vm._v(" " + _vm._s(_vm.getHHMMSS((_scope$row$statistics = scope.row.statistics) === null || _scope$row$statistics === void 0 ? void 0 : _scope$row$statistics.total)) + " ")])];
      }
    }])
  }, [_c('template', {
    slot: "header"
  }, [_c('div', {
    staticClass: "table-th--title"
  }, [_vm._v(" Часов на рабочем месте ")]), _c('div', {
    staticClass: "employee-report-total-time employee-report-total-time_error"
  }, [_c('span', {
    staticClass: "employee-report-total-time__label"
  }, [_vm._v("Всего: ")]), _c('span', {
    staticClass: "employee-report-total-time__value"
  }, [_vm._v(" " + _vm._s((_vm$employeeReportTot = _vm.employeeReportTotal) === null || _vm$employeeReportTot === void 0 ? void 0 : _vm$employeeReportTot.worktime) + " ")])])])], 2), _c('el-table-column', {
    attrs: {
      "class-name": "employee-report-table__column-5",
      "index": 4,
      "min-width": "200"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        var _scope$row$statistics2;

        return [_c('div', {
          staticClass: "employee-report-table__content",
          attrs: {
            "data-header": "Перерыв факт:"
          }
        }, [_vm._v(" " + _vm._s(_vm.getHHMMSS((_scope$row$statistics2 = scope.row.statistics) === null || _scope$row$statistics2 === void 0 ? void 0 : _scope$row$statistics2.dinner)) + " ")])];
      }
    }])
  }, [_c('template', {
    slot: "header"
  }, [_c('div', {
    staticClass: "table-th--title"
  }, [_vm._v(" Перерыв факт ")]), _c('div', {
    staticClass: "employee-report-total-time employee-report-total-time_error"
  }, [_c('span', {
    staticClass: "employee-report-total-time__label"
  }, [_vm._v("Всего: ")]), _c('span', {
    staticClass: "employee-report-total-time__value"
  }, [_vm._v(" " + _vm._s((_vm$employeeReportTot2 = _vm.employeeReportTotal) === null || _vm$employeeReportTot2 === void 0 ? void 0 : _vm$employeeReportTot2.dinner) + " ")])])])], 2), _c('el-table-column', {
    attrs: {
      "class-name": "employee-report-table__column-6",
      "index": 5,
      "min-width": "200"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "employee-report-table__content",
          attrs: {
            "data-header": "Экранное время"
          }
        }, [_c('div', {
          staticClass: "employee-report-app-list"
        }, [_c('employee-report-activities-list', {
          attrs: {
            "items": scope.row.activities
          },
          on: {
            "change": function change($event) {
              return _vm.updateActivities($event, scope.row.activities);
            }
          }
        })], 1)])];
      }
    }])
  }, [_c('template', {
    slot: "header"
  }, [_c('div', {
    staticClass: "table-th--title"
  }, [_vm._v(" Экранное время ")])])], 2), _c('el-table-column', {
    attrs: {
      "class-name": "employee-report-table__column-3",
      "index": 2,
      "min-width": "200"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        var _scope$row$statistics3;

        return [_c('div', {
          staticClass: "employee-report-table__content",
          attrs: {
            "data-header": "Часов отработано:"
          }
        }, [_vm._v(" " + _vm._s(_vm.getHHMMSS((_scope$row$statistics3 = scope.row.statistics) === null || _scope$row$statistics3 === void 0 ? void 0 : _scope$row$statistics3.work_time)) + " ")])];
      }
    }])
  }, [_c('template', {
    slot: "header"
  }, [_c('div', {
    staticClass: "table-th--title"
  }, [_vm._v(" Часов отработано ")]), _c('div', {
    staticClass: "employee-report-total-time employee-report-total-time_error"
  }, [_c('span', {
    staticClass: "employee-report-total-time__label"
  }, [_vm._v("Всего: ")]), _c('span', {
    staticClass: "employee-report-total-time__value"
  }, [_vm._v(" " + _vm._s((_vm$employeeReportTot3 = _vm.employeeReportTotal) === null || _vm$employeeReportTot3 === void 0 ? void 0 : _vm$employeeReportTot3.count) + " ")])])])], 2), _c('el-table-column', {
    attrs: {
      "index": 6,
      "min-width": "200"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        var _scope$row$statistics4;

        return [_c('div', {
          staticClass: "employee-plan__table-item"
        }, [scope.row.pay_by_hours ? _c('span', [_vm._v(" " + _vm._s(Math.ceil((_scope$row$statistics4 = scope.row.statistics) === null || _scope$row$statistics4 === void 0 ? void 0 : _scope$row$statistics4.pay_sum)) + " ")]) : _c('span', {
          staticClass: "color-muted"
        }, [_vm._v("–")])])];
      }
    }])
  }, [_c('template', {
    slot: "header"
  }, [_vm._v(" Сумма (баллов) ")])], 2)], 1)], 1), _c('div', {
    staticClass: "employee-report__nav"
  }, [_c('Pagination', {
    attrs: {
      "info": _vm.pagination
    },
    on: {
      "changedPage": _vm.changePage
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }