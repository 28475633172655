var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "employee-plan"
  }, [_c('div', {
    staticClass: "desktop employee-plan__table"
  }, [_c('el-table', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      "data": _vm.employeePlan
    }
  }, [_c('el-table-column', {
    attrs: {
      "index": 0
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "table-th--title"
        }, [_vm._v("Дата")]), _c('el-date-picker', {
          key: scope.column.index,
          staticClass: "el-date-editor_filter",
          attrs: {
            "type": "daterange",
            "picker-options": _vm.pickerOptions,
            "format": "dd.MM.yyyy",
            "value-format": "yyyy-MM-dd",
            "start-placeholder": "От",
            "end-placeholder": "До"
          },
          on: {
            "change": _vm.changeDate
          },
          model: {
            value: _vm.date,
            callback: function callback($$v) {
              _vm.date = $$v;
            },
            expression: "date"
          }
        })];
      }
    }, {
      key: "default",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "employee-plan__table-item"
        }, [scope.row.date ? [_c('div', [_vm._v(" " + _vm._s(new Date(scope.row.date).toLocaleString("ru").substr(0, 10)) + " ")]), _c('div', [_vm._v(" " + _vm._s(_vm.getDayOfWeek(scope.row.date)) + " ")])] : _c('span', {
          staticClass: "color-muted"
        }, [_vm._v("-")])], 2)];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "index": 1
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "table-th--title"
        }, [_vm._v("Название проекта")]), _vm.projectsFilterList && _vm.projectsFilterList.length ? _c('el-select', {
          key: scope.column.index,
          staticClass: "el-select_filter",
          attrs: {
            "filterable": "",
            "placeholder": "Все проекты"
          },
          on: {
            "change": _vm.filterByProject
          },
          model: {
            value: _vm.selectedProject,
            callback: function callback($$v) {
              _vm.selectedProject = $$v;
            },
            expression: "selectedProject"
          }
        }, _vm._l(_vm.projectsFilterList, function (item) {
          return _c('el-option', {
            key: item.id,
            attrs: {
              "label": item.title,
              "value": item.id
            }
          });
        }), 1) : _vm._e()];
      }
    }, {
      key: "default",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "employee-plan__table-item"
        }, [scope.row.project.title ? _c('span', {
          staticClass: "employee-plan__table-item--bold"
        }, [_vm._v(" " + _vm._s(scope.row.project.title) + " ")]) : _vm._e(), _c('br'), scope.row.project.address ? _c('span', {
          staticClass: "hide-sm hide-xs"
        }, [_vm._v(" " + _vm._s(scope.row.project.address) + " ")]) : _vm._e()])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "index": 3,
      "align": 'center'
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "employee-plan__table-item"
        }, [scope.row.plan_time ? _c('span', [_c('el-dropdown', {
          staticClass: "hide-sm hide-xs",
          attrs: {
            "trigger": "click"
          }
        }, [_c('span', {
          staticClass: "el-dropdown-link dinner-list-more__link"
        }, [_vm._v(" " + _vm._s(_vm.getHoursAndMinutes(scope.row.plan_time)) + " ")]), _c('el-dropdown-menu', {
          staticClass: "offers__options",
          attrs: {
            "slot": "dropdown"
          },
          slot: "dropdown"
        }, [_c('div', {
          staticClass: "schedule"
        }, [_c('div', {
          staticClass: "schedule-row"
        }, [_c('div', {
          staticClass: "schedule-title dinner-list-more__container"
        }, [_vm._v(" План: "), _c('div', {
          staticClass: "dinner-list-more"
        }, [_vm._v(" " + _vm._s(scope.row.start_date_plan.slice(0, -3)) + " - " + _vm._s(scope.row.end_date_plan.slice(0, -3)) + " ")])])])])])], 1)], 1) : _c('span', {
          staticClass: "color-muted"
        }, [_vm._v("–")])])];
      }
    }])
  }, [_c('template', {
    slot: "header"
  }, [_vm._v(" Часов по плану ")])], 2), _c('el-table-column', {
    attrs: {
      "index": 4,
      "align": 'center'
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "employee-plan__table-item",
          class: {
            'is-edit': _vm.activeRowIndex === scope.$index
          }
        }, [_vm.activeRowIndex === scope.$index ? [_c('el-input', {
          ref: "plan_".concat(scope.$index),
          staticClass: "employee-plan__table-edit-input",
          attrs: {
            "type": "number"
          },
          model: {
            value: _vm.workedTime,
            callback: function callback($$v) {
              _vm.workedTime = _vm._n(typeof $$v === 'string' ? $$v.trim() : $$v);
            },
            expression: "workedTime"
          }
        })] : [scope.row.worktime ? _c('span', [_c('el-dropdown', {
          staticClass: "hide-sm hide-xs",
          attrs: {
            "trigger": "click"
          }
        }, [_c('span', {
          staticClass: "el-dropdown-link dinner-list-more__link"
        }, [_vm._v(" " + _vm._s(_vm.getHoursAndMinutes((new Date(scope.row.worktime_end_date).getTime() - new Date(scope.row.worktime_start_date).getTime()) / 1000)) + " ")]), _c('el-dropdown-menu', {
          staticClass: "offers__options",
          attrs: {
            "slot": "dropdown"
          },
          slot: "dropdown"
        }, [_c('div', {
          staticClass: "schedule"
        }, [_c('div', {
          staticClass: "schedule-row"
        }, [_c('div', {
          staticClass: "schedule-title dinner-list-more__container"
        }, [_vm._v(" Отработано: "), _c('div', {
          staticClass: "dinner-list-more"
        }, [_vm._v(" " + _vm._s(_vm.timeToLabel(new Date(scope.row.worktime_start_date))) + " - " + _vm._s(_vm.timeToLabel(new Date(scope.row.worktime_end_date))) + " ")])])])])])], 1)], 1) : _c('span', {
          staticClass: "color-muted"
        }, [_vm._v("–")])]], 2)];
      }
    }])
  }, [_c('template', {
    slot: "header"
  }, [_c('span', {
    staticClass: "hide-sm hide-xs"
  }, [_vm._v("Часов отработано")]), _c('span', {
    staticClass: "hide-lg hide-md"
  }, [_vm._v("Отработано")])])], 2), _c('el-table-column', {
    attrs: {
      "index": 5,
      "align": 'center'
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "employee-plan__table-item"
        }, [_c('div', {
          staticClass: "el-dropdown-link hide-lg hide-md",
          on: {
            "click": function click($event) {
              return _vm.showMoreDinner($event, scope.row);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.getHoursAndMinutes(scope.row.real_dinner)) + " "), _c('div', {
          staticClass: "hide-sm hide-xs js-content"
        }, [_c('div', {
          staticClass: "dinner-list-more__container"
        }, [_c('div', {
          staticClass: "dinner-list-more"
        }, _vm._l(_vm.employeePlanDinnerMore[scope.row.worktime_id], function (item, index) {
          return _c('span', {
            key: item + index,
            staticClass: "dinner-list-more__item"
          }, [_vm._v(" " + _vm._s(item) + " ")]);
        }), 0)])])]), _c('el-dropdown', {
          staticClass: "hide-sm hide-xs",
          attrs: {
            "trigger": "click"
          },
          on: {
            "visible-change": function visibleChange($event) {
              return _vm.handleDropDownDinner($event, scope.row);
            }
          }
        }, [_c('span', {
          staticClass: "el-dropdown-link dinner-list-more__link"
        }, [_vm._v(" " + _vm._s(_vm.getHoursAndMinutes(scope.row.real_dinner)) + " ")]), _c('el-dropdown-menu', {
          staticClass: "offers__options",
          attrs: {
            "slot": "dropdown"
          },
          slot: "dropdown"
        }, [_c('div', {
          staticClass: "schedule"
        }, [_c('div', {
          staticClass: "schedule-row"
        }, [_c('div', {
          staticClass: "schedule-title dinner-list-more__container"
        }, [_vm._v(" Перерыв: "), _c('div', {
          staticClass: "dinner-list-more"
        }, _vm._l(_vm.employeePlanDinnerMore[scope.row.worktime_id], function (item, index) {
          return _c('span', {
            key: item + index,
            staticClass: "dinner-list-more__item"
          }, [_vm._v(" " + _vm._s(item) + " ")]);
        }), 0)])])])])], 1)], 1)];
      }
    }])
  }, [_c('template', {
    slot: "header"
  }, [_vm._v(" Перерыв ")])], 2), _c('el-table-column', {
    attrs: {
      "index": 5,
      "align": 'center'
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "employee-plan__table-item"
        }, [_c('div', [_vm._v(" " + _vm._s(_vm.getHoursAndMinutes(scope.row.worktime)) + " ")])])];
      }
    }])
  }, [_c('template', {
    slot: "header"
  }, [_vm._v(" Итого часов ")])], 2), _c('el-table-column', {
    attrs: {
      "index": 2,
      "width": "120",
      "align": 'center'
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.worktime_start_date ? _c('router-link', {
          staticClass: "employee-plan__steps",
          attrs: {
            "to": {
              name: 'Steps',
              params: {
                id: _vm.$route.params.id,
                projectId: scope.row.project.id,
                vacancyId: scope.row.id,
                date: scope.row.date
              },
              query: {
                date: scope.row.worktime_start_date.split('T')[0]
              }
            }
          }
        }, [_vm._v(" " + _vm._s(_vm._f("integer")(scope.row.sum_steps)) + " "), _c('span', {
          staticClass: "hide-lg hide-md"
        }, [_vm._v(" шагов")])]) : _vm._e()];
      }
    }])
  }, [_c('template', {
    slot: "header"
  }, [_vm._v(" Кол-во шагов ")])], 2), _c('el-table-column', {
    attrs: {
      "index": 6,
      "width": "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "employee-plan__table-actions"
        }, [_c('div', {
          staticClass: "hide-sm hide-xs"
        }, [_vm.activeRowIndex === scope.$index ? [_c('button', {
          staticClass: "employee-plan__table-edit-check",
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.editCheck();
            }
          }
        }, [_c('img', {
          staticClass: "employee-plan__table-edit-check-img",
          attrs: {
            "src": require("@/assets/icons/icon-check-blue.svg"),
            "alt": "edit-check"
          }
        })])] : [_c('el-dropdown', {
          attrs: {
            "trigger": "click"
          },
          on: {
            "command": _vm.handleCommand
          }
        }, [_c('span', {
          staticClass: "el-dropdown-link"
        }, [_c('img', {
          attrs: {
            "src": require("@/assets/icons/icon-option.svg"),
            "alt": "commands"
          }
        })]), _c('el-dropdown-menu', {
          staticClass: "employee-plan__options",
          attrs: {
            "slot": "dropdown"
          },
          slot: "dropdown"
        }, [_c('el-dropdown-item', {
          attrs: {
            "command": {
              name: 'more',
              id: scope.row.project.id
            }
          }
        }, [_c('img', {
          attrs: {
            "src": require("@/assets/icons/icon-info.svg"),
            "alt": "more"
          }
        }), _vm._v(" Подробнее ")]), _c('el-dropdown-item', {
          attrs: {
            "command": {
              name: 'edit',
              activeRowIndex: scope.$index,
              vacancyId: scope.row.id,
              workTimeId: scope.row.worktime_id,
              workedTime: Math.round(scope.row.worktime / 60 / 60 * 10) / 10,
              workedDate: scope.row.date
            }
          }
        }, [_c('img', {
          attrs: {
            "src": require("@/assets/icons/icon-edit.svg"),
            "alt": "edit"
          }
        }), _vm._v(" Редактировать ")]), _c('el-dropdown-item', {
          staticClass: "el-dropdown-menu__item removed",
          attrs: {
            "command": {
              name: 'dismiss',
              id: scope.row.id,
              worker_id: scope.row.worker_id
            }
          }
        }, [_c('img', {
          attrs: {
            "src": require("@/assets/icons/icon-removed-red.svg"),
            "alt": "dismiss"
          }
        }), _vm._v(" Уволить из проекта ")])], 1)], 1)]], 2)])];
      }
    }])
  })], 1)], 1), _c('div', {
    staticClass: "mobile"
  }, [_c('div', {
    staticClass: "mobile__options"
  }, [_c('el-date-picker', {
    staticClass: "el-date-editor_filter",
    attrs: {
      "type": "daterange",
      "picker-options": _vm.pickerOptions,
      "format": "dd.MM.yyyy",
      "value-format": "yyyy-MM-dd",
      "start-placeholder": "От",
      "end-placeholder": "До",
      "clearable": false
    },
    on: {
      "change": _vm.changeDate
    },
    model: {
      value: _vm.date,
      callback: function callback($$v) {
        _vm.date = $$v;
      },
      expression: "date"
    }
  })], 1), _vm._l(_vm.employeePlan, function (row, index) {
    return _c('div', {
      key: row.id + index,
      staticClass: "card"
    }, [_c('div', {
      staticClass: "row"
    }, [_c('div', {
      staticClass: "col"
    }, [_vm._v("Дата")]), _c('div', {
      staticClass: "col"
    }, [_c('div', {
      staticClass: "employee-plan__table-item"
    }, [row.date ? [_c('div', [_vm._v(" " + _vm._s(new Date(row.date).toLocaleString("ru").substr(0, 10)) + " ")]), _c('div', [_vm._v(" " + _vm._s(_vm.getDayOfWeek(row.date)) + " ")])] : _c('span', {
      staticClass: "color-muted"
    }, [_vm._v("-")])], 2)])]), _c('div', {
      staticClass: "row"
    }, [_c('div', {
      staticClass: "col"
    }, [_vm._v("Название проекта")]), _c('div', {
      staticClass: "col"
    }, [_c('div', {
      staticClass: "employee-plan__table-item"
    }, [row.project.title ? _c('router-link', {
      attrs: {
        "to": "/projects/".concat(row.project.id)
      }
    }, [_c('b', [_vm._v(_vm._s(row.project.title))])]) : _vm._e(), _c('br'), row.project.address ? _c('span', [_vm._v(" " + _vm._s(row.project.address) + " ")]) : _vm._e()], 1)])]), _c('div', {
      staticClass: "row"
    }, [_c('div', {
      staticClass: "col"
    }, [_vm._v("Часов по плану")]), _c('div', {
      staticClass: "col"
    }, [_c('div', {
      staticClass: "employee-plan__table-item"
    }, [row.plan_time ? _c('span', [_c('el-dropdown', {
      attrs: {
        "trigger": "click"
      }
    }, [_c('span', {
      staticClass: "el-dropdown-link dinner-list-more__link link"
    }, [_vm._v(" " + _vm._s(_vm.getHoursAndMinutes(row.plan_time)) + " ")]), _c('el-dropdown-menu', {
      staticClass: "offers__options",
      attrs: {
        "slot": "dropdown"
      },
      slot: "dropdown"
    }, [_c('div', {
      staticClass: "schedule"
    }, [_c('div', {
      staticClass: "schedule-row"
    }, [_c('div', {
      staticClass: "schedule-title dinner-list-more__container"
    }, [_vm._v(" План: "), _c('div', {
      staticClass: "dinner-list-more"
    }, [_vm._v(" " + _vm._s(row.start_date_plan.slice(0, -3)) + " - " + _vm._s(row.end_date_plan.slice(0, -3)) + " ")])])])])])], 1)], 1) : _c('span', {
      staticClass: "color-muted"
    }, [_vm._v("–")])])])]), _c('div', {
      staticClass: "row"
    }, [_c('div', {
      staticClass: "col"
    }, [_vm._v("Часов отработано")]), _c('div', {
      staticClass: "col"
    }, [_vm._v(" " + _vm._s(row.worktime_end_date ? _vm.getHoursAndMinutes((new Date(row.worktime_end_date).getTime() - new Date(row.worktime_start_date).getTime()) / 1000) : "-") + " ")])]), _c('div', {
      staticClass: "row"
    }, [_c('div', {
      staticClass: "col"
    }, [_vm._v("Перерыв")]), _c('div', {
      staticClass: "col"
    }, [_c('div', {
      staticClass: "employee-plan__table-item"
    }, [_c('el-dropdown', {
      attrs: {
        "trigger": "click"
      },
      on: {
        "visible-change": function visibleChange($event) {
          return _vm.handleDropDownDinner($event, row);
        }
      }
    }, [_c('span', {
      staticClass: "el-dropdown-link dinner-list-more__link link"
    }, [_vm._v(" " + _vm._s(_vm.getHoursAndMinutes(row.real_dinner)) + " ")]), _c('el-dropdown-menu', {
      staticClass: "offers__options",
      attrs: {
        "slot": "dropdown"
      },
      slot: "dropdown"
    }, [_c('div', {
      staticClass: "schedule"
    }, [_c('div', {
      staticClass: "schedule-row"
    }, [_c('div', {
      staticClass: "schedule-title dinner-list-more__container"
    }, [_vm._v(" Перерыв: "), _c('div', {
      staticClass: "dinner-list-more"
    }, _vm._l(_vm.employeePlanDinnerMore[row.worktime_id], function (item, index) {
      return _c('span', {
        key: item + index,
        staticClass: "dinner-list-more__item"
      }, [_vm._v(" " + _vm._s(item) + " ")]);
    }), 0)])])])])], 1)], 1)])]), _c('div', {
      staticClass: "row"
    }, [_c('div', {
      staticClass: "col"
    }, [_vm._v("Итого часов")]), _c('div', {
      staticClass: "col"
    }, [_vm._v(_vm._s(_vm.getHoursAndMinutes(row.worktime)))])]), _c('div', {
      staticClass: "row"
    }, [_c('div', {
      staticClass: "col"
    }, [_vm._v("Кол-во шагов")]), _c('div', {
      staticClass: "col"
    }, [row.worktime_start_date ? _c('router-link', {
      staticClass: "employee-plan__steps link",
      attrs: {
        "to": {
          name: 'Steps',
          params: {
            id: _vm.$route.params.id,
            projectId: row.project.id,
            vacancyId: row.id,
            date: row.date
          },
          query: {
            date: row.worktime_start_date.split('T')[0]
          }
        }
      }
    }, [_vm._v(" " + _vm._s(_vm._f("integer")(row.sum_steps)) + " ")]) : _vm._e()], 1)]), _c('div', {
      staticClass: "row"
    }, [_c('div', {
      staticClass: "col"
    }), _c('div', {
      staticClass: "col"
    }, [_c('div', {
      staticClass: "employee-plan__table-actions"
    }, [_c('div', {
      staticClass: "more-options"
    }, [_c('span', {
      staticClass: "el-dropdown-link",
      on: {
        "click": function click($event) {
          row.visibleMoreOptions = true;
        }
      }
    }, [_c('img', {
      attrs: {
        "src": require("@/assets/icons/icon-option.svg")
      }
    })]), _c('el-dialog', {
      attrs: {
        "title": "Действия",
        "visible": row.visibleMoreOptions,
        "width": "288px"
      },
      on: {
        "update:visible": function updateVisible($event) {
          return _vm.$set(row, "visibleMoreOptions", $event);
        }
      }
    }, [_c('el-dropdown-item', {
      nativeOn: {
        "click": function click($event) {
          row.visibleMoreOptions = false;

          _vm.handleCommand({
            name: 'more',
            id: row.project.id
          });
        }
      }
    }, [_c('img', {
      attrs: {
        "src": require("@/assets/icons/icon-info.svg"),
        "alt": "more"
      }
    }), _vm._v(" Подробнее ")]), _c('el-dropdown-item', {
      nativeOn: {
        "click": function click($event) {
          row.visibleMoreOptions = false;

          _vm.showEditModal({
            name: 'edit',
            activeRowIndex: row.id,
            vacancyId: row.id,
            workTimeId: row.worktime_id,
            workedTime: Math.round(row.worktime / 60 / 60 * 10) / 10,
            workedDate: row.date
          });
        }
      }
    }, [_c('img', {
      attrs: {
        "src": require("@/assets/icons/icon-edit.svg"),
        "alt": "edit"
      }
    }), _vm._v(" Редактировать ")]), _c('el-dropdown-item', {
      staticClass: "el-dropdown-menu__item removed",
      nativeOn: {
        "click": function click($event) {
          row.visibleMoreOptions = false;

          _vm.handleCommand({
            name: 'dismiss',
            id: row.id,
            worker_id: row.worker_id
          });
        }
      }
    }, [_c('img', {
      attrs: {
        "src": require("@/assets/icons/icon-removed-red.svg"),
        "alt": "dismiss"
      }
    }), _vm._v(" Уволить из проекта ")])], 1)], 1)])])])]);
  })], 2), _c('div', {
    staticClass: "employee-plan__nav"
  }, [_c('Pagination', {
    attrs: {
      "info": _vm.pagination
    },
    on: {
      "changedPage": _vm.changePage
    }
  })], 1), _c('div', {
    staticClass: "employee-plan__edit-modal"
  }, [_c('el-dialog', {
    attrs: {
      "visible": _vm.visibleEditModal,
      "width": "288px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visibleEditModal = $event;
      }
    }
  }, [_c('div', {
    staticClass: "remove-message"
  }, [_c('div', [_c('div', {
    staticClass: "remove-message__title"
  }, [_vm._v("Редактировать")]), _c('div', {
    staticClass: "remove-message__text"
  }, [_vm._v(" Введите необходимые показатели количества часов и сумме ")]), _c('div', {
    staticClass: "remove-message__form"
  }, [_c('div', {
    staticClass: "remove-message__label"
  }, [_vm._v("Часов отработано")]), _c('el-input', {
    attrs: {
      "type": "number"
    },
    model: {
      value: _vm.workedTime,
      callback: function callback($$v) {
        _vm.workedTime = _vm._n(typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "workedTime"
    }
  })], 1)])]), _c('div', {
    staticClass: "employee-plan__action"
  }, [_c('button', {
    staticClass: "el-button el-button--small el-button--primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        _vm.visibleEditModal = false;

        _vm.editCheck();
      }
    }
  }, [_vm._v(" Сохранить ")]), _c('button', {
    staticClass: "employee-plan__cancel",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        _vm.visibleEditModal = false;

        _vm.resetWorkTimeEdit();
      }
    }
  }, [_vm._v(" Отмена ")])])])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }