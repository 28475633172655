<template>
  <div class="employee-report">
    <div class="employee-report-table">
      <el-table ref="tableReport" v-loading="loading" :data="localReportTable">
        <el-table-column
          class-name="employee-report-table__column-1"
          :index="0"
          min-width="200"
        >
          <template slot="header" slot-scope="scope">
            <div class="table-th--title">
              Дата
            </div>
            <el-date-picker
              class="el-date-editor_filter"
              v-model="dateSort"
              type="daterange"
              :picker-options="pickerOptions"
              format="dd.MM.yyyy"
              value-format="yyyy-MM-dd"
              start-placeholder="От"
              end-placeholder="До"
              @change="changeDateSort"
              :clearable="false"
            />
          </template>
          <template class="employee-report-table__column-1" slot-scope="scope">
            <div class="employee-report-table__content">
              <template v-if="scope.row.date">
                <div>
                  {{
                    new Date(scope.row.date).toLocaleString("ru").substr(0, 10)
                  }}
                </div>
                <div>
                  {{ getDayOfWeek(scope.row.date) }}
                </div>
              </template>
              <span v-else class="color-muted">-</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column :index="1" min-width="200">
          <template slot="header" slot-scope="scope">
            <div class="table-th--title">Название проекта</div>
            <el-select
              v-if="projectsFilterList && projectsFilterList.length"
              v-model="selectedProject"
              filterable
              placeholder="Все проекты"
              class="el-select_filter"
              :key="scope.column.index"
              @change="filterByProject"
            >
              <el-option
                v-for="item in projectsFilterList"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              />
            </el-select>
          </template>
          <template slot-scope="scope">
            <div class="employee-plan__table-item">
              <span
                v-if="scope.row.project?.title"
                class="employee-plan__table-item--bold"
              >
                {{ scope.row.project.title }}
              </span>
              <br />
              <span v-if="scope.row.project?.address">
                {{ scope.row.project.address }}
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          class-name="employee-report-table__column-3"
          :index="2"
          min-width="200"
        >
          <template slot="header">
            <div class="table-th--title">
              Часов на рабочем месте
            </div>
            <div
              class="employee-report-total-time employee-report-total-time_error"
            >
              <span class="employee-report-total-time__label">Всего: </span>
              <span class="employee-report-total-time__value">
                {{ employeeReportTotal?.worktime }}
              </span>
            </div>
          </template>
          <template slot-scope="scope">
            <div
              class="employee-report-table__content"
              data-header="Часов на рабочем месте:"
            >
              {{ getHHMMSS(scope.row.statistics?.total) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          class-name="employee-report-table__column-5"
          :index="4"
          min-width="200"
        >
          <template slot="header">
            <div class="table-th--title">
              Перерыв факт
            </div>
            <div
              class="employee-report-total-time employee-report-total-time_error"
            >
              <span class="employee-report-total-time__label">Всего: </span>
              <span class="employee-report-total-time__value">
                {{ employeeReportTotal?.dinner }}
              </span>
            </div>
          </template>
          <template slot-scope="scope">
            <div
              class="employee-report-table__content"
              data-header="Перерыв факт:"
            >
              {{ getHHMMSS(scope.row.statistics?.dinner) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          class-name="employee-report-table__column-6"
          :index="5"
          min-width="200"
        >
          <template slot="header">
            <div class="table-th--title">
              Экранное время
            </div>
          </template>
          <template slot-scope="scope">
            <div
              class="employee-report-table__content"
              data-header="Экранное время"
            >
              <div class="employee-report-app-list">
                <employee-report-activities-list
                  :items="scope.row.activities"
                  @change="updateActivities($event, scope.row.activities)"
                />
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          class-name="employee-report-table__column-3"
          :index="2"
          min-width="200"
        >
          <template slot="header">
            <div class="table-th--title">
              Часов отработано
            </div>
            <div
              class="employee-report-total-time employee-report-total-time_error"
            >
              <span class="employee-report-total-time__label">Всего: </span>
              <span class="employee-report-total-time__value">
                {{ employeeReportTotal?.count }}
              </span>
            </div>
          </template>
          <template slot-scope="scope">
            <div
              class="employee-report-table__content"
              data-header="Часов отработано:"
            >
              {{ getHHMMSS(scope.row.statistics?.work_time) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column :index="6" min-width="200">
          <template slot="header">
            Сумма (баллов)
          </template>
          <template slot-scope="scope">
            <div class="employee-plan__table-item">
              <span v-if="scope.row.pay_by_hours">
                {{ Math.ceil(scope.row.statistics?.pay_sum) }}
              </span>
              <span v-else class="color-muted">&ndash;</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--    <div class="employee-report-mobile">-->
    <!--      <div class="employee-report-filters">-->
    <!--        <el-date-picker-->
    <!--          class="el-date-editor_filter"-->
    <!--          v-model="dateSort"-->
    <!--          type="daterange"-->
    <!--          :picker-options="pickerOptions"-->
    <!--          format="dd.MM.yyyy"-->
    <!--          value-format="yyyy-MM-dd"-->
    <!--          start-placeholder="От"-->
    <!--          end-placeholder="До"-->
    <!--          @change="changeDateSort"-->
    <!--          :clearable="false"-->
    <!--        />-->
    <!--        <el-select-->
    <!--          v-if="projectsFilterList && projectsFilterList.length"-->
    <!--          v-model="selectedProject"-->
    <!--          filterable-->
    <!--          placeholder="Все проекты"-->
    <!--          class="el-select_filter"-->
    <!--          @change="filterByProject"-->
    <!--        >-->
    <!--          <el-option-->
    <!--            v-for="item in projectsFilterList"-->
    <!--            :key="item.id"-->
    <!--            :label="item.title"-->
    <!--            :value="item.id"-->
    <!--          />-->
    <!--        </el-select>-->
    <!--      </div>-->
    <!--      <div class="employee-report-total">-->
    <!--        <div class="employee-report-total__item">-->
    <!--          <b>Всего часов на рабочем месте:</b>-->
    <!--          {{ reportTotalView.worktimeLabel }}-->
    <!--        </div>-->
    <!--        <div class="employee-report-total__item">-->
    <!--          <b>Всего перерыва:</b>-->
    <!--          {{ reportTotalView.breakLabel }}-->
    <!--        </div>-->
    <!--        <div class="employee-report-total__item">-->
    <!--          <b>Всего часов отработано:</b>-->
    <!--          {{ reportTotalView.finalWorktimeLabel }}-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div class="employee-report-cards">-->
    <!--        <report-card-->
    <!--          v-for="card in reportTableView"-->
    <!--          :key="card.id"-->
    <!--          :card="card"-->
    <!--          @update="updateActivities"-->
    <!--        />-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="employee-report__nav">
      <Pagination :info="pagination" @changedPage="changePage" />
    </div>
  </div>
</template>

<script>
import Pagination from "../blocks/Pagination";
import EmployeeReportActivitiesList from "../blocks/EmployeeReportActivities.vue";
import {
  getDayOfWeek,
  getHHMMSS,
  getHoursAndMinutes
} from "../../utils/date-formatter";
// import ReportCard from "@/components/Employee/ReportCard.vue";

export default {
  name: "EmployeeReport",
  components: {
    // ReportCard,
    Pagination,
    EmployeeReportActivitiesList
  },
  data() {
    return {
      pickerOptions: {
        firstDayOfWeek: 1
      },
      loading: false,
      page: 1,
      pageCount: 10,
      date: null,
      selectedProject: "",
      dateSort: null,
      localReportTotal: null,
      localReportTable: null
    };
  },

  computed: {
    employeeReport() {
      return this.$store.state.Employee.employeeReport?.data
        ? this.$store.state.Employee.employeeReport.data
        : [];
    },
    employeeReportTotal() {
      return {
        worktime: getHHMMSS(
          this.$store.state.Employee.employeeReportTotal?.worktime
        ),
        dinner: getHHMMSS(
          this.$store.state.Employee.employeeReportTotal?.dinner
        ),
        count: getHHMMSS(this.$store.state.Employee.employeeReportTotal?.count)
      };
    },
    projectsListAll() {
      return this.$store.state.Employee.projectsListAll;
    },
    projectsFilterList() {
      let arr = [];
      if (this.projectsListAll) {
        arr = JSON.parse(JSON.stringify(this.projectsListAll));
        arr.unshift({ id: "", title: "Все проекты" });
      }
      return arr;
    },
    pagination() {
      let pagination = {
        totalItems: 0,
        totalPage: 0,
        currentPage: this.page
      };
      if (this.$store.state.Employee.employeeReport?.total) {
        pagination.totalItems = this.$store.state.Employee.employeeReport?.total;
        pagination.totalPage = Math.ceil(
          pagination.totalItems / this.pageCount
        );
      }
      return pagination;
    },
    isMobile() {
      const minWidth = 768;
      return window.innerWidth < minWidth || screen.width < minWidth;
    },
    currentEmployee() {
      return this.$store.state.Employee.employeeData;
    }
  },
  watch: {
    employeeReport() {
      this.localReportTable = JSON.parse(JSON.stringify(this.employeeReport));
    },
    employeeReportTotal() {
      this.localReportTotal = { ...this.employeeReportTotal };
    }
  },
  created() {
    if (!this.projectsListAll?.length) {
      this.$store.dispatch("Employee/getProjectsAll", {
        employee: this.$route.params.id
      });
    }
    this.setDefaultDates();
  },
  mounted() {
    this.getData();
  },
  methods: {
    getHHMMSS,
    getDayOfWeek,
    getHoursAndMinutes,
    setDefaultDates() {
      const today = new Date();
      // const offsetStart = 7; //today.getDay() - 1;
      const offsetEnd = 0; //30 - today.getDay();
      const startDate = new Date(
        this.currentEmployee?.created_at?.split("T")[0] || "2020-01-01"
      )
        .toISOString()
        .substr(0, 10);
      const endDate = new Date(
        today.getTime() + offsetEnd * 24 * 60 * 60 * 1000
      )
        .toISOString()
        .substr(0, 10);
      this.dateSort = [startDate, endDate];
    },
    changeDateSort() {
      this.page = 1;
      this.getData();
    },
    filterByProject() {
      this.page = 1;
      this.getData();
    },
    changePage(page, pageCount) {
      this.page = page;
      this.pageCount = pageCount;
      this.getStatisticData();
    },
    updateActivities() {
      // let temActivityApp = activities.find(el => el.id === id);
      // temActivityApp.is_active = status;
      // this.localReportTotal.activity +=
      //   (temActivityApp.is_active ? 1 : -1) * temActivityApp.count;
      // this.localReportTable = [...this.localReportTable];
      this.getData();
    },
    async getData() {
      await Promise.all([this.getTotalData(), this.getStatisticData()]);
    },
    async getStatisticData() {
      const getCountOfDays = (start, end) => {
        const date1 = new Date(start);
        const date2 = new Date(end);

        const oneDay = 1000 * 60 * 60 * 24;
        const diffInTime = date2.getTime() - date1.getTime();
        const diffInDays = Math.round(diffInTime / oneDay);

        return diffInDays + 1;
      };

      let data = {
        date_from: this.dateSort[0],
        date_to: this.dateSort[1],
        page: this.page,
        pageCount: this.pageCount,
        project: this.selectedProject ? this.selectedProject : null
      };
      const total = getCountOfDays(this.dateSort[0], this.dateSort[1]);
      await this.$store.dispatch("Employee/getEmployeeReport", {
        data,
        employee_id: this.$route.params.id,
        total
      });
    },
    async getTotalData() {
      let data = {
        date_from: this.dateSort[0],
        date_to: this.dateSort[1]
      };
      await this.$store.dispatch("Employee/getEmployeeReportTotal", {
        data,
        employee_id: this.$route.params.id
      });
    }
  }
};
</script>

<style lang="scss">
@import "src/assets/styles/helpers/variables";
@import "src/assets/styles/helpers/mixin";

.employee-report {
  &__btn-edit {
    padding: 4px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: 16px;
    margin-left: 4px;

    span {
      height: 16px;
    }
  }

  &__dinner-modal {
    .el-dialog {
      border-radius: 6px;
      width: 368px;

      &__header {
        display: none;
      }

      &__body {
        padding: 40px;
      }
    }
  }
}

.employee-report-app-item {
  width: 100%;

  &:nth-child(1) {
    .employee-report-app-item__checkbox {
      margin-top: 0;
    }
  }

  &__checkbox {
    width: 100%;
    margin-top: 8px;
  }

  .el-checkbox__label {
    width: 100%;
  }
}

.employee-report-app-list {
  &__header {
    display: none;
  }

  &__bottom {
    margin-top: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

.employee-report-app-item-label {
  font-size: 12px;
  line-height: 1.5;

  &__container {
    display: flex;
  }

  &__name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__time {
    margin-left: auto;
    color: #a1adbb;
    flex-shrink: 0;
    padding-left: 8px;
  }
}

.install-dinner {
  &__title {
    font-weight: 600;
    font-size: 2rem;
    line-height: 2.4rem;
    padding-bottom: 0.6rem;
  }

  &__action {
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;

    .el-button {
      width: 100%;
    }
  }

  &__label {
    margin-top: 2.4rem;
    margin-bottom: 0.4rem;
  }

  &__input {
    .el-input {
      &__inner {
        text-align: center;
      }

      &__prefix {
        display: none;
      }
    }

    &.el-range-editor {
      background-color: #f2f9ff;
      display: flex;
      justify-content: center;

      .el-range-input {
        background-color: transparent;
      }

      .el-input__icon {
        display: none;
      }
    }
  }

  &__no-dinner-checkbox {
    margin-top: 1.2rem;
  }
}

.employee-report-total-time {
  font-size: 1.2rem;
  line-height: 3.2rem;
  font-weight: 400;

  &__value {
    font-weight: 700;
    color: $titleDark;
    display: inline-flex;
    align-items: center;
  }

  &_error {
    .employee-report-total-time {
      &__value {
        color: #eb5757;
      }
    }
  }
}

.employee-report-table {
  .el-table {
    th {
      padding: 0;
      vertical-align: top;

      .cell {
        padding-top: 1.6rem;
        padding-bottom: 1.6rem;
      }
    }

    td {
      padding-top: 18px;
      padding-bottom: 18px;
    }
  }
}
.el-date-editor_filter {
  .el-range__close-icon {
    display: none;
  }
}
.steps-header-label {
  display: none;
}

.employee-report-mobile {
  display: none;

  @media (max-width: $screen-sm-max) {
    display: block;
  }
}

.employee-report-filters {
  & > div {
    width: 100%;
    margin-bottom: 10px;
  }
}

.employee-report-total {
  font-size: 1.4rem;
  line-height: 1.9rem;
  color: #31333b;
  background: #d7e3fb;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px;

  &__item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.employee-report-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  .report-card {
    width: calc(50% - 5px);

    @media (max-width: $screen-xs-max) {
      width: 100%;
    }
  }
}

@media (max-width: $screen-sm-max) {
  .install-dinner {
    &__title {
      padding-bottom: 0;
      font-size: 1.4rem;
    }

    &__label {
      margin-top: 1.8rem;
      margin-bottom: 0.4rem;
    }

    &__action {
      flex-direction: column;

      .el-button + .el-button {
        margin-left: 0;
        margin-top: 0.8rem;
      }
    }
  }

  .employee-report {
    &__dinner-modal {
      .el-dialog {
        max-width: 368px;
        width: calc(100% - 3.2rem);

        &__body {
          padding: 2rem;
        }
      }
    }
  }

  .employee-report-app-list {
    &__header {
      display: block;
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 1.9rem;
      margin-bottom: 6px;
      color: #31333b;
    }

    &__bottom {
      justify-content: flex-end;
    }
  }

  .employee-report-total-time {
    line-height: 2.4em;
  }
}
</style>
