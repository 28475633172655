<template>
  <div class="employee">
    <div class="employee__title">
      <div class="employee__title--left">
        <router-link
          :to="{ name: 'Employees' }"
          class="employee__title-link  hide-xs"
        >
          <img src="@/assets/icons/icon-arrow.svg" alt="" />
        </router-link>
        <PageTitle :text="name" />
      </div>
      <div>
        <el-button
          v-if="activeTab === 'projects'"
          class="employee__title-button"
          type="primary"
          @click="hireForProject"
        >
          + Нанять в проект
        </el-button>
        <download-report
          v-if="currentTariff?.can_download_user_stats || userRole === 'admin'"
          :id="employeeData?.id"
          :name="employeeData?.name"
          title="Скачать статистику за период:"
          text-button="Скачать статистику"
          @download="downloadEmployeeReport"
        />
      </div>
    </div>
    <div
      v-if="employeeData"
      class="employee__tabs"
      :class="['employee__tabs--' + activeTab]"
    >
      <el-tabs ref="tabs" v-model="activeTab" @tab-click="changeTab">
        <el-tab-pane label="График" name="plan" :lazy="true">
          <EmployeePlan />
        </el-tab-pane>
        <el-tab-pane label="Табель времени" name="report" :lazy="true">
          <EmployeeReport />
        </el-tab-pane>
        <el-tab-pane label="Проекты" name="projects" :lazy="true">
          <el-button
            v-if="activeTab === 'projects'"
            class="employee__title-button  hide-lg  hide-md  hide-sm"
            type="primary"
            @click="hireForProject"
          >
            + Нанять в проект
          </el-button>
          <EmployeeProjects />
        </el-tab-pane>
        <el-tab-pane label="Профиль" name="profile" :lazy="true">
          <EmployeeCard :data-info="employeeData" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import PageTitle from "../components/elements/PageTitle";
import EmployeeCard from "../components/blocks/EmployeeCard";
import EmployeeProjects from "../components/blocks/EmployeeProjects";
import EmployeePlan from "../components/blocks/EmployeePlan";
import EmployeeReport from "../components/blocks/EmployeeReport";
import DownloadReport from "@/components/blocks/DownloadReport.vue";
import { downloadFile } from "@/utils/download";

export default {
  name: "Employee",
  components: {
    DownloadReport,
    PageTitle,
    EmployeeCard,
    EmployeeProjects,
    EmployeePlan,
    EmployeeReport
  },
  data() {
    return {
      activeTab: "plan",
      hashTab: true,
      pickerOptions: {
        firstDayOfWeek: 1
      }
    };
  },
  computed: {
    employeeData() {
      return this.$store.state.Employee.employeeData;
    },
    name() {
      return this.employeeData ? this.employeeData.name : "";
    },
    currentTariff() {
      return this.$store.getters["Tariffs/currentTariff"];
    },
    userRole() {
      return this.$store.state.Auth.userRole;
    }
  },
  watch: {
    $route() {
      if (this.hashTab) {
        this.hashTabActivate();
      }
    }
  },
  created() {
    if (this.hashTab) {
      this.hashTabActivate();
      this.hashTabReplace();
    }
    if (this.$route.params.id) {
      this.getEmployee(this.$route.params.id);
    }
  },
  methods: {
    getEmployee(id) {
      this.$store.dispatch("Employee/getEmployee", { id });
    },
    changeTab() {
      if (this.hashTab) {
        this.hashTabPush();
      }
    },
    hireForProject() {
      this.$router.push({
        name: "HireForProject",
        query: {
          company: this.employeeData?.company_id,
          worker: this.employeeData?.id
        }
      });
    },
    // Hash tab
    hashTabReplace() {
      if (!this.$route.query.tab) {
        this.$router.replace({ query: { tab: this.activeTab } });
      }
    },
    hashTabPush() {
      this.$router.push({ query: { tab: this.activeTab } });
    },
    hashTabActivate() {
      if (this.$route.query.tab) {
        this.activeTab = this.$route.query.tab;
      }
    },
    // Hash tab end
    downloadEmployeeReport({ dates, id, name }) {
      const data = {
        start_date: dates[0],
        end_date: dates[1]
      };
      this.$store
        .dispatch("Employee/downloadEmployeeReport", { id, data })
        .then(data => {
          downloadFile(data, name);
        })
        .catch(err => {
          console.log(err);
          this.$notify({
            title: "Ошибка",
            message: "Ошибка, попробуйте еще раз",
            type: "error"
          });
        });
    }
  }
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/helpers/variables";
@import "src/assets/styles/helpers/mixin";

.employee {
  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.4rem;

    &--left {
      display: flex;
      align-items: center;
    }

    &-link {
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 4px 4px 24px rgba(70, 80, 119, 0.04);
      border-radius: 6px;
      background: #fff;
      width: 2.8rem;
      height: 2.8rem;
      margin-right: 1rem;
      padding-right: 0.2rem;
      transition: 0.3s ease-out;
      cursor: pointer;

      &:hover {
        box-shadow: 4px 4px 24px rgba(70, 80, 119, 0.44);
      }
    }

    &-button {
      margin-right: 10px;
    }
  }

  &__tabs {
    &--projects,
    &--plan,
    &--report {
      .el-tab-pane {
        background-color: transparent;
        padding: 0;
      }
    }
  }
}

@media (max-width: $screen-sm-max) {
  .employee {
    &__title {
      margin-bottom: 20px;
      font-size: 20px;
      line-height: 24px;
    }

    &__tabs {
      margin-left: -16px;
      margin-right: -16px;

      .el-tab-pane {
        border-radius: 0;
        padding: 32px 16px;
        box-shadow: none;
      }

      &__tabs--projects,
      &__tabs--plan,
      &__tabs--report {
        .el-tab-pane {
          padding-top: 0;
          background: none;
        }
      }
    }
  }
}

@media (max-width: $screen-xs-max) {
  .employee__title-button {
    position: static;
    width: 100%;
    margin-bottom: 24px;
  }
}
</style>
