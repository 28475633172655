var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "employee-projects"
  }, [_c('div', {
    staticClass: "employee-projects__table desktop"
  }, [_c('el-table', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      "data": _vm.employeeProjects
    },
    on: {
      "header-click": _vm.sortData
    }
  }, [_c('el-table-column', {
    attrs: {
      "index": 0,
      "label-class-name": _vm.typeSort === 'title' ? 'is-sortable sortable ' + _vm.sortOrder : 'is-sortable'
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "table-th--title"
        }, [_vm._v(" Название проекта "), _c('svg', {
          attrs: {
            "width": "6",
            "height": "9",
            "viewBox": "0 0 6 9",
            "fill": "none",
            "xmlns": "http://www.w3.org/2000/svg"
          }
        }, [_c('path', {
          attrs: {
            "d": "M3 5H0L3 9L6 5H3Z",
            "fill": "#A2A7BE"
          }
        }), _c('path', {
          attrs: {
            "d": "M3 4L6 4L3 0L0 4L3 4Z",
            "fill": "#A2A7BE"
          }
        })])]), _vm.statusList.length ? _c('el-select', {
          key: scope.column.index,
          staticClass: "el-select_filter",
          attrs: {
            "placeholder": "Статус проекта"
          },
          on: {
            "change": _vm.filterByStatus
          },
          model: {
            value: _vm.selectedStatus,
            callback: function callback($$v) {
              _vm.selectedStatus = $$v;
            },
            expression: "selectedStatus"
          }
        }, _vm._l(_vm.statusList, function (item) {
          return _c('el-option', {
            key: item.value,
            attrs: {
              "label": item.name,
              "value": item.value
            }
          });
        }), 1) : _vm._e()];
      }
    }, {
      key: "default",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "employee-projects__table-item",
          on: {
            "click": function click($event) {
              return _vm.getDetailProject(scope.row.project_id);
            }
          }
        }, [_c('span', {
          staticClass: "employee-projects__table-item--bold"
        }, [_vm._v(" " + _vm._s(scope.row.title) + " ")]), _c('br'), _c('span', [_vm._v(" " + _vm._s(scope.row.address) + " ")])])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "index": 1
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "professions__table-item"
        }, [scope.row.workday_start ? _c('span', [_vm._v(" " + _vm._s(_vm.getHHMMFromString(scope.row.workday_start)) + " ")]) : _c('span', {
          staticClass: "color-muted"
        }, [_vm._v("--")])])];
      }
    }])
  }, [_c('template', {
    slot: "header"
  }, [_c('span', {
    staticClass: "hide-sm  hide-xs"
  }, [_vm._v("Начало работ")]), _c('span', {
    staticClass: "hide-lg  hide-md"
  }, [_vm._v("Проект")])])], 2), _c('el-table-column', {
    attrs: {
      "index": 7
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "professions__table-item"
        }, [scope.row.dinner_start && scope.row.dinner_end ? _c('span', [_vm._v(" " + _vm._s(_vm.getHHMMFromString(scope.row.dinner_start)) + " - " + _vm._s(_vm.getHHMMFromString(scope.row.dinner_end)) + " ")]) : _c('span', {
          staticClass: "color-muted"
        }, [_vm._v("--")])])];
      }
    }])
  }, [_c('template', {
    slot: "header"
  }, [_c('span', [_vm._v("Время перерыва")])])], 2), _c('el-table-column', {
    attrs: {
      "index": 2
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "professions__table-item"
        }, [scope.row.workday_end ? _c('span', [_vm._v(" " + _vm._s(_vm.getHHMMFromString(scope.row.workday_end)) + " ")]) : _c('span', {
          staticClass: "color-muted"
        }, [_vm._v("--")])])];
      }
    }])
  }, [_c('template', {
    slot: "header"
  }, [_vm._v(" Окончание работ ")])], 2), _c('el-table-column', {
    attrs: {
      "index": 3
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "table-th--title"
        }, [_vm._v(" Специальности ")]), _vm.specialitiesFilterList && _vm.specialitiesFilterList.length ? _c('el-select', {
          key: scope.column.index,
          staticClass: "el-select_filter",
          attrs: {
            "filterable": "",
            "placeholder": "Все специальности"
          },
          on: {
            "change": _vm.filterBySpeciality
          },
          model: {
            value: _vm.selectedSpeciality,
            callback: function callback($$v) {
              _vm.selectedSpeciality = $$v;
            },
            expression: "selectedSpeciality"
          }
        }, _vm._l(_vm.specialitiesFilterList, function (item) {
          return _c('el-option', {
            key: item.id,
            attrs: {
              "label": item.name,
              "value": item.id
            }
          });
        }), 1) : _vm._e()];
      }
    }, {
      key: "default",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "employee-projects__table-item"
        }, [scope.row.speciality ? _c('span', [_vm._v(" " + _vm._s(scope.row.speciality.name) + " ")]) : _c('span', {
          staticClass: "color-muted"
        }, [_vm._v("Специальностей нет")])])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "index": 4,
      "width": "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "professions__table-item"
        }, [scope.row.payment ? _c('span', [_vm._v(" " + _vm._s(scope.row.payment) + " ")]) : _c('span', {
          staticClass: "color-muted"
        }, [_vm._v("--")])])];
      }
    }])
  }, [_c('template', {
    slot: "header"
  }, [_vm._v(" Ставка ")])], 2), _c('el-table-column', {
    attrs: {
      "index": 5,
      "width": "160",
      "align": 'center'
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "professions__table-item"
        }, [_vm._v(" " + _vm._s(scope.row.show_stavka ? "Да" : "Нет") + " ")])];
      }
    }])
  }, [_c('template', {
    slot: "header"
  }, [_vm._v(" Показывать ставку ")])], 2), _c('el-table-column', {
    attrs: {
      "index": 6,
      "width": "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "employee-projects__table-actions"
        }, [_c('el-dropdown', {
          staticClass: "hide-sm  hide-xs",
          attrs: {
            "trigger": "click"
          },
          on: {
            "command": _vm.handleCommand
          }
        }, [_c('span', {
          staticClass: "el-dropdown-link"
        }, [_c('img', {
          attrs: {
            "src": require("@/assets/icons/icon-option.svg"),
            "alt": "commands"
          }
        })]), _c('el-dropdown-menu', {
          staticClass: "employee-projects__options",
          attrs: {
            "slot": "dropdown"
          },
          slot: "dropdown"
        }, [_c('el-dropdown-item', {
          attrs: {
            "command": {
              name: 'more',
              id: scope.row.project_id
            }
          }
        }, [_c('img', {
          attrs: {
            "src": require("@/assets/icons/icon-info.svg"),
            "alt": "more"
          }
        }), _vm._v(" Подробнее ")]), _c('el-dropdown-item', {
          staticClass: "el-dropdown-menu__item removed",
          attrs: {
            "command": {
              name: 'dismiss',
              id: scope.row.id,
              worker_id: scope.row.worker_id
            }
          }
        }, [_c('img', {
          attrs: {
            "src": require("@/assets/icons/icon-removed-red.svg"),
            "alt": "dismiss"
          }
        }), _vm._v(" Уволить из проекта ")])], 1)], 1)], 1)];
      }
    }])
  })], 1)], 1), _c('div', {
    staticClass: "mobile"
  }, _vm._l(_vm.employeeProjects, function (row) {
    return _c('div', {
      key: row.id,
      staticClass: "card"
    }, [_c('div', {
      staticClass: "row"
    }, [_c('div', {
      staticClass: "col"
    }, [_vm._v(" Название проекта ")]), _c('div', {
      staticClass: "col"
    }, [_c('div', {
      staticClass: "employee-projects__table-item",
      on: {
        "click": function click($event) {
          return _vm.getDetailProject(row.project_id);
        }
      }
    }, [_c('span', {
      staticClass: "employee-projects__table-item--bold"
    }, [_vm._v(" " + _vm._s(row.title) + " ")]), _c('br'), _c('span', [_vm._v(" " + _vm._s(row.address) + " ")])])])]), _c('div', {
      staticClass: "row"
    }, [_c('div', {
      staticClass: "col"
    }, [_vm._v("Начало работ")]), _c('div', {
      staticClass: "col"
    }, [_c('div', {
      staticClass: "professions__table-item"
    }, [row.workday_start ? _c('span', [_vm._v(" " + _vm._s(_vm.getHHMMFromString(row.workday_start)) + " ")]) : _c('span', {
      staticClass: "color-muted"
    }, [_vm._v("--")])])])]), _c('div', {
      staticClass: "row"
    }, [_c('div', {
      staticClass: "col"
    }, [_vm._v("Время перерыва")]), _c('div', {
      staticClass: "col"
    }, [_c('div', {
      staticClass: "professions__table-item"
    }, [row.dinner_start && row.dinner_end ? _c('span', [_vm._v(" " + _vm._s(_vm.getHHMMFromString(row.dinner_start)) + " - " + _vm._s(_vm.getHHMMFromString(row.dinner_end)) + " ")]) : _c('span', {
      staticClass: "color-muted"
    }, [_vm._v("--")])])])]), _c('div', {
      staticClass: "row"
    }, [_c('div', {
      staticClass: "col"
    }, [_vm._v(" Окончание работ ")]), _c('div', {
      staticClass: "col"
    }, [_c('div', {
      staticClass: "professions__table-item"
    }, [row.workday_end ? _c('span', [_vm._v(" " + _vm._s(_vm.getHHMMFromString(row.workday_end)) + " ")]) : _c('span', {
      staticClass: "color-muted"
    }, [_vm._v("--")])])])]), _c('div', {
      staticClass: "row"
    }, [_c('div', {
      staticClass: "col"
    }, [_vm._v(" Специальности ")]), _c('div', {
      staticClass: "col"
    }, [_c('div', {
      staticClass: "employee-projects__table-item"
    }, [row.speciality ? _c('span', [_vm._v(" " + _vm._s(row.speciality.name) + " ")]) : _c('span', {
      staticClass: "color-muted"
    }, [_vm._v("Специальностей нет")])])])]), _c('div', {
      staticClass: "row"
    }, [_c('div', {
      staticClass: "col"
    }, [_vm._v("Ставка")]), _c('div', {
      staticClass: "col"
    }, [_c('div', {
      staticClass: "professions__table-item"
    }, [row.payment ? _c('span', [_vm._v(" " + _vm._s(row.payment) + " ")]) : _c('span', {
      staticClass: "color-muted"
    }, [_vm._v("--")])])])]), _c('div', {
      staticClass: "row"
    }, [_c('div', {
      staticClass: "col"
    }, [_vm._v(" Показывать ставку ")]), _c('div', {
      staticClass: "col"
    }, [_vm._v(" " + _vm._s(row.show_stavka ? "Да" : "Нет") + " ")])]), _c('div', {
      staticClass: "row"
    }, [_c('div', {
      staticClass: "col"
    }), _c('div', {
      staticClass: "col"
    }, [_c('div', {
      staticClass: "more-options"
    }, [_c('span', {
      staticClass: "el-dropdown-link",
      on: {
        "click": function click($event) {
          _vm.visibleMoreOptions = true;
          _vm.activeProject = row;
        }
      }
    }, [_c('img', {
      attrs: {
        "src": require("@/assets/icons/icon-option.svg")
      }
    })])])])])]);
  }), 0), _c('div', {
    staticClass: "employee-projects__nav"
  }, [_c('Pagination', {
    attrs: {
      "info": _vm.pagination
    },
    on: {
      "changedPage": _vm.changePage
    }
  })], 1), _c('el-dialog', {
    attrs: {
      "title": "Действия",
      "visible": _vm.visibleMoreOptions,
      "width": "288px",
      "custom-class": "more-options"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visibleMoreOptions = $event;
      }
    }
  }, [_c('el-dropdown-item', {
    nativeOn: {
      "click": function click($event) {
        _vm.visibleMoreOptions = false;

        _vm.handleCommand({
          name: 'more',
          id: _vm.activeProject.project_id
        });
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icons/icon-info.svg"),
      "alt": "more"
    }
  }), _vm._v(" Подробнее ")]), _c('el-dropdown-item', {
    staticClass: "el-dropdown-menu__item removed",
    nativeOn: {
      "click": function click($event) {
        _vm.visibleMoreOptions = false;

        _vm.handleCommand({
          name: 'dismiss',
          id: _vm.activeProject.id,
          worker_id: _vm.activeProject.worker_id
        });
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icons/icon-removed-red.svg"),
      "alt": "dismiss"
    }
  }), _vm._v(" Уволить из проекта ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }