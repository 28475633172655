var render = function () {
  var _vm$currentTariff, _vm$employeeData, _vm$employeeData2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "employee"
  }, [_c('div', {
    staticClass: "employee__title"
  }, [_c('div', {
    staticClass: "employee__title--left"
  }, [_c('router-link', {
    staticClass: "employee__title-link  hide-xs",
    attrs: {
      "to": {
        name: 'Employees'
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icons/icon-arrow.svg"),
      "alt": ""
    }
  })]), _c('PageTitle', {
    attrs: {
      "text": _vm.name
    }
  })], 1), _c('div', [_vm.activeTab === 'projects' ? _c('el-button', {
    staticClass: "employee__title-button",
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.hireForProject
    }
  }, [_vm._v(" + Нанять в проект ")]) : _vm._e(), (_vm$currentTariff = _vm.currentTariff) !== null && _vm$currentTariff !== void 0 && _vm$currentTariff.can_download_user_stats || _vm.userRole === 'admin' ? _c('download-report', {
    attrs: {
      "id": (_vm$employeeData = _vm.employeeData) === null || _vm$employeeData === void 0 ? void 0 : _vm$employeeData.id,
      "name": (_vm$employeeData2 = _vm.employeeData) === null || _vm$employeeData2 === void 0 ? void 0 : _vm$employeeData2.name,
      "title": "Скачать статистику за период:",
      "text-button": "Скачать статистику"
    },
    on: {
      "download": _vm.downloadEmployeeReport
    }
  }) : _vm._e()], 1)]), _vm.employeeData ? _c('div', {
    staticClass: "employee__tabs",
    class: ['employee__tabs--' + _vm.activeTab]
  }, [_c('el-tabs', {
    ref: "tabs",
    on: {
      "tab-click": _vm.changeTab
    },
    model: {
      value: _vm.activeTab,
      callback: function callback($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, [_c('el-tab-pane', {
    attrs: {
      "label": "График",
      "name": "plan",
      "lazy": true
    }
  }, [_c('EmployeePlan')], 1), _c('el-tab-pane', {
    attrs: {
      "label": "Табель времени",
      "name": "report",
      "lazy": true
    }
  }, [_c('EmployeeReport')], 1), _c('el-tab-pane', {
    attrs: {
      "label": "Проекты",
      "name": "projects",
      "lazy": true
    }
  }, [_vm.activeTab === 'projects' ? _c('el-button', {
    staticClass: "employee__title-button  hide-lg  hide-md  hide-sm",
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.hireForProject
    }
  }, [_vm._v(" + Нанять в проект ")]) : _vm._e(), _c('EmployeeProjects')], 1), _c('el-tab-pane', {
    attrs: {
      "label": "Профиль",
      "name": "profile",
      "lazy": true
    }
  }, [_c('EmployeeCard', {
    attrs: {
      "data-info": _vm.employeeData
    }
  })], 1)], 1)], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }