var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.activities.length ? _c('div', [_vm._l(_vm.activities, function (item, index) {
    return _c('div', {
      key: item.id,
      staticClass: "employee-report-app-item"
    }, [_c('el-checkbox', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.isMore ? true : index < _vm.appsActivityMaxItem,
        expression: "isMore ? true : index < appsActivityMaxItem"
      }],
      staticClass: "employee-report-app-item__checkbox",
      attrs: {
        "checked": Boolean(item.is_active)
      },
      on: {
        "change": function change($event) {
          return _vm.changeStatusApp($event, item.id);
        }
      }
    }, [_c('div', {
      staticClass: "employee-report-app-item-label"
    }, [_c('div', {
      staticClass: "employee-report-app-item-label__container"
    }, [_c('div', {
      staticClass: "employee-report-app-item-label__name"
    }, [_vm._v(" " + _vm._s(item.app) + " ")]), _c('div', {
      staticClass: "employee-report-app-item-label__time"
    }, [_vm._v(" " + _vm._s(item.countLabel) + " ")])]), _c('el-progress', {
      attrs: {
        "percentage": item.percent,
        "show-text": false,
        "color": item.is_active ? '#FFB74B' : '#f4dab3'
      }
    })], 1)])], 1);
  }), _vm.activities && _vm.activities.length > _vm.appsActivityMaxItem ? _c('div', {
    staticClass: "employee-report-app-list__bottom"
  }, [_c('el-link', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function click() {
        _vm.isMore = !_vm.isMore;
      }
    }
  }, [_c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isMore,
      expression: "!isMore"
    }]
  }, [_vm._v(" Развернуть "), _c('i', {
    staticClass: "el-icon-arrow-down"
  })]), _c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isMore,
      expression: "isMore"
    }]
  }, [_vm._v(" Свернуть "), _c('i', {
    staticClass: "el-icon-arrow-up"
  })])])], 1) : _vm._e()], 2) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }