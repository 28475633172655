<template>
  <div v-if="activities.length">
    <div
      v-for="(item, index) of activities"
      :key="item.id"
      class="employee-report-app-item"
    >
      <el-checkbox
        v-show="isMore ? true : index < appsActivityMaxItem"
        class="employee-report-app-item__checkbox"
        :checked="Boolean(item.is_active)"
        @change="changeStatusApp($event, item.id)"
      >
        <div class="employee-report-app-item-label">
          <div class="employee-report-app-item-label__container">
            <div class="employee-report-app-item-label__name">
              {{ item.app }}
            </div>
            <div class="employee-report-app-item-label__time">
              {{ item.countLabel }}
            </div>
          </div>
          <el-progress
            :percentage="item.percent"
            :show-text="false"
            :color="item.is_active ? '#FFB74B' : '#f4dab3'"
          >
          </el-progress>
        </div>
      </el-checkbox>
    </div>
    <div
      class="employee-report-app-list__bottom"
      v-if="activities && activities.length > appsActivityMaxItem"
    >
      <el-link
        type="primary"
        @click="
          () => {
            isMore = !isMore;
          }
        "
      >
        <span v-show="!isMore">
          Развернуть <i class="el-icon-arrow-down"></i>
        </span>
        <span v-show="isMore"> Свернуть <i class="el-icon-arrow-up"></i> </span>
      </el-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  emits: ["change"],
  data() {
    return {
      isMore: false,
      appsActivityMaxItem: 3
    };
  },
  computed: {
    activitiesTotalTime() {
      const sumActivitiesTime = (sum, current) => sum + current.count;
      return this.items.reduce(sumActivitiesTime, 0);
    },
    activities() {
      const secondToTime = function(second) {
        const hours = Math.floor(second / 60 / 60);
        const minute = Math.floor(((second / 60 / 60) % 1) * 60);
        let result =
          (hours ? `${hours} ч` : "") + (minute ? ` ${minute} м` : "");
        return result === "" ? "<1 м" : result;
      };
      const sortActivities = (a, b) => {
        return b.is_active - a.is_active == 0
          ? b.count - a.count
          : b.is_active - a.is_active;
      };

      const result = this.items
        .map(el => ({
          ...el,
          countLabel: secondToTime(el.count),
          percent: (el.count / this.activitiesTotalTime) * 100
        }))
        .sort(sortActivities);
      return result;
    }
  },
  methods: {
    async changeStatusApp(status, id) {
      await this.$store.dispatch("Employee/putEmployeeReportChangeAppStatus", {
        status,
        id
      });
      this.$emit("change", { status, id });
    }
  }
};
</script>

<style></style>
