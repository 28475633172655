<template>
  <div class="employee-plan">
    <div class="desktop employee-plan__table">
      <el-table v-loading="loading" :data="employeePlan">
        <el-table-column :index="0">
          <template slot="header" slot-scope="scope">
            <div class="table-th--title">Дата</div>
            <el-date-picker
              class="el-date-editor_filter"
              v-model="date"
              type="daterange"
              :picker-options="pickerOptions"
              format="dd.MM.yyyy"
              value-format="yyyy-MM-dd"
              :key="scope.column.index"
              start-placeholder="От"
              end-placeholder="До"
              @change="changeDate"
            />
          </template>
          <template slot-scope="scope">
            <div class="employee-plan__table-item">
              <template v-if="scope.row.date">
                <div>
                  {{
                    new Date(scope.row.date).toLocaleString("ru").substr(0, 10)
                  }}
                </div>
                <div>
                  {{ getDayOfWeek(scope.row.date) }}
                </div>
              </template>
              <span v-else class="color-muted">-</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column :index="1">
          <template slot="header" slot-scope="scope">
            <div class="table-th--title">Название проекта</div>
            <el-select
              v-if="projectsFilterList && projectsFilterList.length"
              v-model="selectedProject"
              filterable
              placeholder="Все проекты"
              class="el-select_filter"
              :key="scope.column.index"
              @change="filterByProject"
            >
              <el-option
                v-for="item in projectsFilterList"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              />
            </el-select>
          </template>
          <template slot-scope="scope">
            <div class="employee-plan__table-item">
              <span
                v-if="scope.row.project.title"
                class="employee-plan__table-item--bold"
              >
                {{ scope.row.project.title }}
              </span>
              <br />
              <span v-if="scope.row.project.address" class="hide-sm hide-xs">
                {{ scope.row.project.address }}
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column :index="3" :align="'center'">
          <template slot="header"> Часов по плану </template>
          <template slot-scope="scope">
            <div class="employee-plan__table-item">
              <span v-if="scope.row.plan_time">
                <el-dropdown trigger="click" class="hide-sm hide-xs">
                  <span class="el-dropdown-link dinner-list-more__link">
                    {{ getHoursAndMinutes(scope.row.plan_time) }}
                  </span>
                  <el-dropdown-menu slot="dropdown" class="offers__options">
                    <div class="schedule">
                      <div class="schedule-row">
                        <div class="schedule-title dinner-list-more__container">
                          План:
                          <div class="dinner-list-more">
                            {{ scope.row.start_date_plan.slice(0, -3) }} -
                            {{ scope.row.end_date_plan.slice(0, -3) }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </el-dropdown-menu>
                </el-dropdown>
              </span>
              <span v-else class="color-muted">&ndash;</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column :index="4" :align="'center'">
          <template slot="header">
            <span class="hide-sm hide-xs">Часов отработано</span>
            <span class="hide-lg hide-md">Отработано</span>
          </template>
          <template slot-scope="scope">
            <div
              :class="{ 'is-edit': activeRowIndex === scope.$index }"
              class="employee-plan__table-item"
            >
              <template v-if="activeRowIndex === scope.$index">
                <el-input
                  class="employee-plan__table-edit-input"
                  v-model.number.trim="workedTime"
                  type="number"
                  :ref="`plan_${scope.$index}`"
                />
              </template>
              <template v-else>
                <span v-if="scope.row.worktime">
                  <el-dropdown trigger="click" class="hide-sm hide-xs">
                    <span class="el-dropdown-link dinner-list-more__link">
                      {{
                        getHoursAndMinutes(
                          (new Date(scope.row.worktime_end_date).getTime() -
                            new Date(scope.row.worktime_start_date).getTime()) /
                            1000
                        )
                      }}
                    </span>
                    <el-dropdown-menu slot="dropdown" class="offers__options">
                      <div class="schedule">
                        <div class="schedule-row">
                          <div
                            class="schedule-title dinner-list-more__container"
                          >
                            Отработано:
                            <div class="dinner-list-more">
                              {{
                                timeToLabel(
                                  new Date(scope.row.worktime_start_date)
                                )
                              }}
                              -
                              {{
                                timeToLabel(
                                  new Date(scope.row.worktime_end_date)
                                )
                              }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-dropdown-menu>
                  </el-dropdown>
                </span>
                <span v-else class="color-muted">&ndash;</span>
              </template>
            </div>
          </template>
        </el-table-column>
        <el-table-column :index="5" :align="'center'">
          <template slot="header"> Перерыв </template>
          <template slot-scope="scope">
            <div class="employee-plan__table-item">
              <div
                @click="showMoreDinner($event, scope.row)"
                class="el-dropdown-link hide-lg hide-md"
              >
                {{ getHoursAndMinutes(scope.row.real_dinner) }}
                <div class="hide-sm hide-xs js-content">
                  <div class="dinner-list-more__container">
                    <div class="dinner-list-more">
                      <span
                        v-for="(item, index) in employeePlanDinnerMore[
                          scope.row.worktime_id
                        ]"
                        :key="item + index"
                        class="dinner-list-more__item"
                      >
                        {{ item }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <el-dropdown
                trigger="click"
                @visible-change="handleDropDownDinner($event, scope.row)"
                class="hide-sm hide-xs"
              >
                <span class="el-dropdown-link dinner-list-more__link">
                  {{ getHoursAndMinutes(scope.row.real_dinner) }}
                </span>
                <el-dropdown-menu slot="dropdown" class="offers__options">
                  <div class="schedule">
                    <div class="schedule-row">
                      <div class="schedule-title dinner-list-more__container">
                        Перерыв:
                        <div class="dinner-list-more">
                          <span
                            v-for="(item, index) in employeePlanDinnerMore[
                              scope.row.worktime_id
                            ]"
                            :key="item + index"
                            class="dinner-list-more__item"
                          >
                            {{ item }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
        </el-table-column>
        <el-table-column :index="5" :align="'center'">
          <template slot="header"> Итого часов </template>
          <template slot-scope="scope">
            <div class="employee-plan__table-item">
              <div>
                {{ getHoursAndMinutes(scope.row.worktime) }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column :index="2" width="120" :align="'center'">
          <template slot="header"> Кол-во шагов </template>
          <template slot-scope="scope">
            <router-link
              v-if="scope.row.worktime_start_date"
              :to="{
                name: 'Steps',
                params: {
                  id: $route.params.id,
                  projectId: scope.row.project.id,
                  vacancyId: scope.row.id,
                  date: scope.row.date
                },
                query: {
                  date: scope.row.worktime_start_date.split('T')[0]
                }
              }"
              class="employee-plan__steps"
            >
              {{ scope.row.sum_steps | integer }}
              <span class="hide-lg hide-md"> шагов</span>
            </router-link>
          </template>
        </el-table-column>
        <el-table-column :index="6" width="100">
          <template slot-scope="scope">
            <div class="employee-plan__table-actions">
              <div class="hide-sm hide-xs">
                <template v-if="activeRowIndex === scope.$index">
                  <button
                    class="employee-plan__table-edit-check"
                    @click.prevent="editCheck()"
                  >
                    <img
                      class="employee-plan__table-edit-check-img"
                      src="@/assets/icons/icon-check-blue.svg"
                      alt="edit-check"
                    />
                  </button>
                </template>
                <template v-else>
                  <el-dropdown trigger="click" @command="handleCommand">
                    <span class="el-dropdown-link">
                      <img
                        src="@/assets/icons/icon-option.svg"
                        alt="commands"
                      />
                    </span>
                    <el-dropdown-menu
                      slot="dropdown"
                      class="employee-plan__options"
                    >
                      <el-dropdown-item
                        :command="{ name: 'more', id: scope.row.project.id }"
                      >
                        <img src="@/assets/icons/icon-info.svg" alt="more" />
                        Подробнее
                      </el-dropdown-item>
                      <el-dropdown-item
                        :command="{
                          name: 'edit',
                          activeRowIndex: scope.$index,
                          vacancyId: scope.row.id,
                          workTimeId: scope.row.worktime_id,
                          workedTime:
                            Math.round((scope.row.worktime / 60 / 60) * 10) /
                            10,
                          workedDate: scope.row.date
                        }"
                      >
                        <img src="@/assets/icons/icon-edit.svg" alt="edit" />
                        Редактировать
                      </el-dropdown-item>
                      <el-dropdown-item
                        :command="{
                          name: 'dismiss',
                          id: scope.row.id,
                          worker_id: scope.row.worker_id
                        }"
                        class="el-dropdown-menu__item removed"
                      >
                        <img
                          src="@/assets/icons/icon-removed-red.svg"
                          alt="dismiss"
                        />
                        Уволить из проекта
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="mobile">
      <div class="mobile__options">
        <el-date-picker
          class="el-date-editor_filter"
          v-model="date"
          type="daterange"
          :picker-options="pickerOptions"
          format="dd.MM.yyyy"
          value-format="yyyy-MM-dd"
          start-placeholder="От"
          end-placeholder="До"
          @change="changeDate"
          :clearable="false"
        />
      </div>
      <div
        v-for="(row, index) in employeePlan"
        :key="row.id + index"
        class="card"
      >
        <div class="row">
          <div class="col">Дата</div>
          <div class="col">
            <div class="employee-plan__table-item">
              <template v-if="row.date">
                <div>
                  {{ new Date(row.date).toLocaleString("ru").substr(0, 10) }}
                </div>
                <div>
                  {{ getDayOfWeek(row.date) }}
                </div>
              </template>
              <span v-else class="color-muted">-</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">Название проекта</div>
          <div class="col">
            <div class="employee-plan__table-item">
              <router-link
                v-if="row.project.title"
                :to="`/projects/${row.project.id}`"
              >
                <b>{{ row.project.title }}</b>
              </router-link>
              <br />
              <span v-if="row.project.address">
                {{ row.project.address }}
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">Часов по плану</div>
          <div class="col">
            <div class="employee-plan__table-item">
              <span v-if="row.plan_time">
                <el-dropdown trigger="click">
                  <span class="el-dropdown-link dinner-list-more__link link">
                    {{ getHoursAndMinutes(row.plan_time) }}
                  </span>
                  <el-dropdown-menu slot="dropdown" class="offers__options">
                    <div class="schedule">
                      <div class="schedule-row">
                        <div class="schedule-title dinner-list-more__container">
                          План:
                          <div class="dinner-list-more">
                            {{ row.start_date_plan.slice(0, -3) }} -
                            {{ row.end_date_plan.slice(0, -3) }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </el-dropdown-menu>
                </el-dropdown>
              </span>
              <span v-else class="color-muted">&ndash;</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">Часов отработано</div>
          <div class="col">
            {{
              row.worktime_end_date
                ? getHoursAndMinutes(
                    (new Date(row.worktime_end_date).getTime() -
                      new Date(row.worktime_start_date).getTime()) /
                      1000
                  )
                : "-"
            }}
          </div>
        </div>
        <div class="row">
          <div class="col">Перерыв</div>
          <div class="col">
            <div class="employee-plan__table-item">
              <el-dropdown
                trigger="click"
                @visible-change="handleDropDownDinner($event, row)"
              >
                <span class="el-dropdown-link dinner-list-more__link link">
                  {{ getHoursAndMinutes(row.real_dinner) }}
                </span>
                <el-dropdown-menu slot="dropdown" class="offers__options">
                  <div class="schedule">
                    <div class="schedule-row">
                      <div class="schedule-title dinner-list-more__container">
                        Перерыв:
                        <div class="dinner-list-more">
                          <span
                            v-for="(item, index) in employeePlanDinnerMore[
                              row.worktime_id
                            ]"
                            :key="item + index"
                            class="dinner-list-more__item"
                          >
                            {{ item }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">Итого часов</div>
          <div class="col">{{ getHoursAndMinutes(row.worktime) }}</div>
        </div>
        <div class="row">
          <div class="col">Кол-во шагов</div>
          <div class="col">
            <router-link
              v-if="row.worktime_start_date"
              :to="{
                name: 'Steps',
                params: {
                  id: $route.params.id,
                  projectId: row.project.id,
                  vacancyId: row.id,
                  date: row.date
                },
                query: {
                  date: row.worktime_start_date.split('T')[0]
                }
              }"
              class="employee-plan__steps link"
            >
              {{ row.sum_steps | integer }}
            </router-link>
          </div>
        </div>
        <div class="row">
          <div class="col"></div>
          <div class="col">
            <div class="employee-plan__table-actions">
              <div class="more-options">
                <span
                  @click="row.visibleMoreOptions = true"
                  class="el-dropdown-link"
                >
                  <img src="@/assets/icons/icon-option.svg" />
                </span>
                <el-dialog
                  title="Действия"
                  :visible.sync="row.visibleMoreOptions"
                  width="288px"
                >
                  <el-dropdown-item
                    @click.native="
                      row.visibleMoreOptions = false;
                      handleCommand({ name: 'more', id: row.project.id });
                    "
                  >
                    <img src="@/assets/icons/icon-info.svg" alt="more" />
                    Подробнее
                  </el-dropdown-item>
                  <el-dropdown-item
                    @click.native="
                      row.visibleMoreOptions = false;
                      showEditModal({
                        name: 'edit',
                        activeRowIndex: row.id,
                        vacancyId: row.id,
                        workTimeId: row.worktime_id,
                        workedTime:
                          Math.round((row.worktime / 60 / 60) * 10) / 10,
                        workedDate: row.date
                      });
                    "
                  >
                    <img src="@/assets/icons/icon-edit.svg" alt="edit" />
                    Редактировать
                  </el-dropdown-item>
                  <el-dropdown-item
                    @click.native="
                      row.visibleMoreOptions = false;
                      handleCommand({
                        name: 'dismiss',
                        id: row.id,
                        worker_id: row.worker_id
                      });
                    "
                    class="el-dropdown-menu__item removed"
                  >
                    <img
                      src="@/assets/icons/icon-removed-red.svg"
                      alt="dismiss"
                    />
                    Уволить из проекта
                  </el-dropdown-item>
                </el-dialog>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="employee-plan__nav">
      <Pagination :info="pagination" @changedPage="changePage" />
    </div>
    <div class="employee-plan__edit-modal">
      <el-dialog :visible.sync="visibleEditModal" width="288px">
        <div class="remove-message">
          <div>
            <div class="remove-message__title">Редактировать</div>
            <div class="remove-message__text">
              Введите необходимые показатели количества часов и сумме
            </div>
            <div class="remove-message__form">
              <div class="remove-message__label">Часов отработано</div>
              <el-input v-model.number.trim="workedTime" type="number" />
            </div>
          </div>
        </div>
        <div class="employee-plan__action">
          <button
            @click="
              visibleEditModal = false;
              editCheck();
            "
            type="button"
            class="el-button el-button--small el-button--primary"
          >
            Сохранить
          </button>
          <button
            @click="
              visibleEditModal = false;
              resetWorkTimeEdit();
            "
            type="button"
            class="employee-plan__cancel"
          >
            Отмена
          </button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import Pagination from "../blocks/Pagination";
import MixinFormatting from "../mixin/MixinFormatting";
import { getDayOfWeek, getHoursAndMinutes } from "../../utils/date-formatter";

export default {
  name: "EmployeePlan",
  mixins: [MixinFormatting],
  components: {
    Pagination
  },
  data() {
    return {
      pickerOptions: {
        firstDayOfWeek: 1
      },
      loading: false,
      visibleEditModal: false,
      page: 1,
      pageCount: 10,
      date: null,
      selectedProject: "",
      activeRowIndex: null,
      vacancyId: null,
      workTimeId: null,
      workedTime: null,
      workedTimeInit: null,
      workedDate: null,
      employee: null
    };
  },
  computed: {
    employeePlan() {
      return this.$store.state.Employee.employeePlan?.map(item => ({
        ...item,
        visibleMoreOptions: false
      }));
    },
    employeePlanDinnerMore() {
      let result = {};
      for (let key in this.$store.state.Employee.employeePlanDinnerMore) {
        let list = this.$store.state.Employee.employeePlanDinnerMore[key];
        result[key] = list.map(el => {
          let from = new Date(el.from);
          let to = new Date(el.to);
          return `${this.timeToLabel(from)} - ${this.timeToLabel(to)}`;
        });
      }
      return result;
    },
    projectsListAll() {
      return this.$store.state.Employee.projectsListAll;
    },
    projectsFilterList() {
      let arr = [];
      if (this.projectsListAll) {
        arr = JSON.parse(JSON.stringify(this.projectsListAll));
        arr.unshift({ id: "", title: "Все проекты" });
      }
      return arr;
    },
    pagination() {
      return this.$store.state.Employee.employeePlanPagination;
    }
  },
  created() {
    this.setDefaultDates();
    this.getData();
    this.$store.dispatch("Employee/getSpecialities");
    this.$store.dispatch("Employee/getProjectsAll", {
      employee: this.$route.params.id
    });
    this.employee = this.$route.params.id;
  },
  mounted() {
    document.addEventListener("keyup", e => {
      if (this.activeRowIndex !== null && e.key === "Escape") {
        this.resetWorkTimeEdit();
      }
    });
    document.addEventListener("click", e => {
      if (
        this.activeRowIndex !== null &&
        !e.target.closest(".employee-plan__table") &&
        !e.target.closest(".el-dropdown-menu") &&
        !e.target.closest(".employee-plan__edit-modal")
      ) {
        this.resetWorkTimeEdit();
      }
    });
  },
  methods: {
    getDayOfWeek,
    getHoursAndMinutes,
    getData() {
      this.getPlan();
    },
    getPlan() {
      this.loading = true;
      const id = this.$route.params.id;
      const params = {
        page: this.page,
        pageCount: this.pageCount,
        project: this.selectedProject ? this.selectedProject : null,
        date_from: this.date ? this.date[0] : null,
        date_to: this.date ? this.date[1] : null
      };
      this.$store
        .dispatch("Employee/getPlanByEmployeeId", { params, id })
        .then(() => {
          this.loading = false;
        });
    },
    timeToLabel(date) {
      let h = date.getHours();
      let m = date.getMinutes();
      return `${h < 10 ? "0" + h : h}:${m < 10 ? "0" + m : m}`;
    },
    changePage(page, count) {
      this.resetWorkTimeEdit();
      this.page = page;
      this.pageCount = count;
      this.getData();
    },
    handleCommand(command) {
      switch (command.name) {
        case "more":
          this.$router.push({
            name: "Project",
            params: { id: command.id }
          });
          break;
        case "edit":
          this.editPlan(
            command.activeRowIndex,
            command.vacancyId,
            command.workTimeId,
            command.workedTime,
            command.workedDate
          );
          break;
        case "dismiss":
          this.dismissEmployee(command.id, command.worker_id);
          break;
      }
    },
    dismissEmployee(id, worker_id) {
      this.$confirm(
        `<div class="remove-message">
        <img src="${require("@/assets/icons/icon-delete-red.svg")}" alt="Удаление исполнителя">
        <div>
          <div class="remove-message__title">
            Уволить исполнителя
          </div>
          <div class="remove-message__text">
            Вы действительно хотите уволить испольнителя?
          </div>
        </div>
      </div>`,
        {
          customClass: "remove-message__box",
          dangerouslyUseHTMLString: true,
          confirmButtonText: "Уволить",
          cancelButtonText: "Отменить",
          showClose: false
        }
      ).then(() => {
        const data = {
          worker_id
        };
        this.$store
          .dispatch("Employee/dismissEmployee", { data, id })
          .then(() => {
            this.getProjects();
            this.$notify({
              title: "Успех",
              message: "Сотрудник уволен",
              type: "success"
            });
          })
          .catch(err => {
            console.error(err);
            this.$notify({
              title: "Ошибка",
              message: "Ошибка, попробуйте еще раз",
              type: "error"
            });
          });
      });
    },
    editPlan(activeRowIndex, vacancyId, workTimeId, workedTime, workedDate) {
      this.activeRowIndex = activeRowIndex;
      this.vacancyId = vacancyId;
      this.workTimeId = workTimeId;
      this.workedTime = this.workedTimeInit = workedTime;
      this.workedDate = workedDate.split(".").join("-");
      this.focusInput();
    },
    editCheck() {
      if (this.workedTime === this.workedTimeInit) {
        this.resetWorkTimeEdit();
        return false;
      }
      this.loading = true;
      let methodName;
      let payload;
      const workTimeId = this.workTimeId;
      if (workTimeId) {
        methodName = "Employee/setWorkedTime";
        payload = {
          data: {
            count: this.workedTime * 60 * 60
          },
          id: workTimeId
        };
      } else {
        methodName = "Employee/setWorkedTimeInit";
        payload = {
          data: {
            count: this.workedTime * 60 * 60,
            date: this.workedDate,
            user_id: this.$route.params.id,
            vacancy_id: this.vacancyId
          }
        };
      }
      this.$store
        .dispatch(methodName, payload)
        .then(() => {
          this.getData();

          this.loading = false;

          this.resetWorkTimeEdit();

          this.$notify({
            title: "Успех",
            message: "Отработаное время изменено",
            type: "success"
          });
        })
        .catch(() => {
          this.loading = false;

          this.$notify({
            title: "Ошибка",
            message: "Ошибка, попробуйте еще раз",
            type: "error"
          });
        });
    },
    filterByProject() {
      this.page = 1;
      this.getData();
    },
    changeDate() {
      this.page = 1;
      this.getData();
    },
    focusInput() {
      setTimeout(() => {
        this.$refs[`plan_${this.activeRowIndex}`].$refs.input.select();
      });
    },
    resetWorkTimeEdit() {
      this.activeRowIndex = null;
      this.workedDate = null;
      this.vacancyId = null;
      this.workTimeId = null;
      this.workedTime = null;
      this.workedTimeInit = null;
    },
    setDefaultDates() {
      const today = new Date();
      // const offsetStart = 1000; // today.getDay() - 1
      const offsetEnd = 0; //  30 -  today.getDay()
      const startDate = new Date("2020-01-01").toISOString().substr(0, 10);
      const endDate = new Date(
        today.getTime() + offsetEnd * 24 * 60 * 60 * 1000
      )
        .toISOString()
        .substr(0, 10);
      this.date = [startDate, endDate];
    },
    showEditModal(options) {
      this.handleCommand(options);
      this.visibleEditModal = true;
    },
    async handleDropDownDinner(visible, data) {
      let startDate = new Date(data.date);
      let endDate = new Date();
      endDate.setDate(startDate.getDate() + 1);

      if (visible) {
        await this.$store.dispatch("Employee/getEmployeePlanDinnerMore", {
          id_employee: this.employee,
          id: data.worktime_id,
          data: {
            start_date: `${startDate.getDate()}-${startDate.getMonth() +
              1}-${startDate.getFullYear()}`,
            end_date: `${endDate.getDate()}-${endDate.getMonth() +
              1}-${endDate.getFullYear()}`
          }
        });
      }
    },
    async showMoreDinner(e, data) {
      await this.handleDropDownDinner(true, data);
      this.$confirm(
        `<div class="remove-message">
				<div>
				  <div class="remove-message__title">
					Перерыв:
				  </div>
				  <div class="remove-message__text">
					${e.target.querySelector(".js-content").innerHTML}
				  </div>
				</div>
			  </div>`,
        {
          customClass: "remove-message__box  remove-message__box--warning",
          dangerouslyUseHTMLString: true,
          confirmButtonText: "Понятно",
          showClose: false
        }
      );
    }
  }
};
</script>

<style lang="scss">
@import "src/assets/styles/helpers/variables";
@import "src/assets/styles/helpers/mixin";

.employee-plan {
  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.6rem;

    .el-button {
      width: 20.2rem;
    }
  }

  &__table {
    .el-table th {
      padding: 0;
      vertical-align: top;

      .cell {
        padding-top: 1.6rem;
        padding-bottom: 1.6rem;
      }
    }

    &-item {
      &--bold {
        font-weight: 600;
        color: $text;
      }
    }

    &-list {
      list-style: none;

      &-item {
        margin-bottom: 0.8rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &-status {
      &-icon {
        vertical-align: top;
      }
    }

    &-actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 1.4rem;

      &__chat {
        margin-right: 2rem;
        margin-top: 0.7rem;
      }
    }

    &-edit-input {
      .el-input__inner {
        height: 3.2rem;
        width: 5.6rem;
        font-size: inherit;
        font-family: inherit;
        line-height: calc(3.2rem - 2px);
        text-align: center;
        color: inherit;
        padding: 0 0.5rem;
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input[type="number"] {
        -moz-appearance: textfield;
      }
    }

    &-edit-check {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 4rem;
      width: 4rem;
      border: none;
      background: none;
      margin: 0;
      border-radius: 0;
      -webkit-appearance: none;
      margin: -0.9rem;
      transition: opacity $transition-duration;

      &-img {
        display: block;
        max-width: 100%;
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }

  &__options {
    border-radius: 2px;
    border: none;
    box-shadow: 6px 6px 32px rgba(0, 0, 0, 0.08);

    .el-dropdown-menu__item {
      display: flex;
      align-items: center;
      font-size: 1.2rem;
      line-height: 1.6rem;
      color: $text;
      padding: 0.5rem 2.2rem;

      &.removed {
        color: $error;
      }

      img {
        margin-right: 0.8rem;
      }
    }
  }
}

@media (max-width: $screen-sm-max) {
  .employee-plan {
    .employee-plan__table {
      margin: 0 -16px;
    }

    .el-table__header-wrapper {
      margin-bottom: 4px;
    }
    .el-table__header {
      display: block;
      width: 100% !important;

      thead {
        display: block;
      }

      tr {
        display: flex;
        flex-wrap: wrap;
        background: none;
      }

      th {
        &:nth-child(3),
        &:nth-child(6),
        &:nth-child(7) {
          border-radius: 0;
          width: calc(50% - 12px);
          margin-top: 16px;
          text-align: left;

          .cell {
            padding: 8px 16px;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
          }
        }
        &:nth-child(6) {
          width: calc(40% - 12px);
          .cell {
            padding-left: 0;
          }
        }
        &:nth-child(3) {
          width: calc(40% - 24px);
        }
        &:nth-child(7) {
          width: calc(20% + 36px);
          .cell {
            padding-left: 0;
          }
        }

        &:last-child,
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(8),
        &:nth-child(9) {
          display: none;
        }

        &:nth-child(1),
        &:nth-child(2) {
          margin: 0 16px 4px;
          order: -15;
          width: 100%;
          background: none;

          .cell {
            padding: 0;
          }

          .table-th--title {
            display: none;
          }
        }

        svg {
          display: none;
        }
      }

      .el-select {
        width: 100%;
      }
    }

    .el-table__body-wrapper {
      table,
      tbody {
        display: block;
        width: 100% !important;
      }
      tr {
        display: flex;
        flex-wrap: wrap;
        padding: 16px;
        position: relative;
        margin-bottom: 4px;
      }
      td {
        position: static;
        border-radius: 0;
        padding: 0;
        border: none;
        text-align: left;
        font-size: 12px;
        line-height: 16px;

        &:nth-child(1) {
          width: calc(40% - 12px);
          order: -25;
        }

        &:nth-child(2) {
          width: calc(40% - 12px);
          order: -40;
          font-size: 14px;
          line-height: 20px;
        }

        &:nth-child(4) {
          width: calc(60% - 12px);
          order: -25;
        }

        &:nth-child(5) {
          width: calc(40% - 12px);
          order: -25;
        }

        &:nth-child(6) {
          width: calc(40% - 12px);
          order: -35;
        }
        &:nth-child(7) {
          width: calc(20% - 12px);
          order: -35;
        }

        &:nth-child(9) {
          width: 24px;
          order: -30;
        }

        &:nth-child(3) {
          position: absolute;
          right: 14px;
          bottom: 16px;
        }

        .cell {
          padding: 0;
          line-height: 20px;
        }
      }
      .employee-plan__table-actions__chat {
        position: absolute;
        right: 16px;
        bottom: 16px;
        margin: 0;

        img {
          margin: 0;
          position: static;
        }
      }
    }
  }
  .employee-plan__table-actions {
    padding: 0;
  }
}

.employee-plan__edit-modal {
  .el-dialog__body {
    word-break: break-word;
  }

  .remove-message {
    margin-bottom: 0;
  }
  .el-dialog__header {
    display: none;
  }
  .el-input__inner {
    width: 100%;
    text-align: left;
    padding: 11px 16px;
    height: 42px;
  }
  .remove-message__label {
    margin-top: 16px;
    margin-bottom: 4px;
    font-size: 14px;
    line-height: 20px;
    color: #676973;
  }
}
.employee-plan__action {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;

  .el-button {
    width: 100%;
  }
}
.employee-plan__cancel {
  background: none;
  border: none;
  margin-top: 16px;
  color: #1199f0;
  border-bottom: 1px solid #1199f0;
}

.employee-plan__steps {
  color: #000;
  text-decoration: none;
  @include no-touch {
    &:hover {
      color: #1199f0;
    }
  }
}
.dinner-list-more {
  display: flex;
  flex-direction: column;
  font-weight: normal;
  &__container {
    padding: 8px 16px;
    font-weight: bold;
  }
  &__item {
    display: block;
  }
  &__link {
    &:hover {
      color: $primary;
    }
  }
}
</style>
